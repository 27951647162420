import { FC } from "react";
import {
  Avatar,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";
import { Delete as DeleteIcon } from "@material-ui/icons";
import { IPerson } from "../../Apollo/Queries";

interface IProps {
  id: string;
  email: string;
  handleOpenDeleteDialog: (item: IPerson) => void;
}

export const AdminListItem: FC<IProps> = (props) => {
  const { email, id, handleOpenDeleteDialog } = props;

  const handleDelete = () => {
    handleOpenDeleteDialog({ id, email });
  };

  return (
    <ListItem divider>
      <ListItemAvatar>
        <Avatar />
      </ListItemAvatar>
      <ListItemText>{email}</ListItemText>
      <ListItemSecondaryAction>
        <IconButton color="secondary" onClick={handleDelete}>
          <DeleteIcon color="secondary" />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};
