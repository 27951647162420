import { createTypedInputs } from "../../utils";

export const initialInputData = createTypedInputs({
  title: {
    value: "",
    label: "Naziv",
    required: true,
  },
  description: {
    value: "",
    label: "Opis",
    required: false,
  },
  link: {
    value: "",
    label: "Link",
    required: false,
    helperText: "Mora sadržavati https://",
  },
  longitude: {
    value: "",
    label: "Zemljopisna dužina",
    required: true,
    type: "number",
  },
  latitude: {
    value: "",
    label: "Zemljopisna širina",
    type: "number",
    required: true,
  },
  category: {
    value: "",
    label: "Kategorija",
    required: true,
    type: "number",
  },
});
