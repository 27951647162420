import { gql } from "@apollo/client";

export interface ICreatePromotion {
  id: number;
  title: string;
  category: {
    id: string;
    name: string;
  };
  media: {
    id: string;
    path: string;
  };
  expired: string;
  created: string;
  updated: string;
}

export interface ICreatePromotionData {
  createPromotion: ICreatePromotion;
}

export interface ICreatePromotionInputVars {
  data: {
    title: string;
    media: { connect: { id: number } };
    category: { connect: { id: number } };
  };
}

export const CREATE_PROMOTION = gql`
  mutation createPromotion($data: PromotionCreateInput) {
    createPromotion(data: $data) {
      id
      title
      media {
        id
        path
      }
      category {
        id
        name
      }
      created
      updated
      expired
    }
  }
`;
