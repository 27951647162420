import { Attributes, FC, ReactChild } from "react";
import { useFocused, useSelected } from "slate-react";
import { useStyles } from "./YoutubeRte.styles";
import { Element as SlateElement } from "slate";

interface IProps {
  attributes: Attributes;
  children: ReactChild | ReactChild[];
  element: SlateElement;
}

export const YoutubeRte: FC<IProps> = ({ attributes, children, element }) => {
  const selected = useSelected();
  const focused = useFocused();
  const styles = useStyles();

  return (
    <div {...attributes}>
      <div contentEditable={false}>
        <iframe
          title="Youtube video"
          src={`https://www.youtube.com/embed/${element.url}?rel=0&controls=0&modestbranding=1" frameBorder="0"`}
          aria-label="Youtube video"
          frameBorder="0"
          className={`${styles.imageBlock} ${
            selected && focused ? styles.imageBlockActive : ""
          }`}
        />
      </div>
      {children}
    </div>
  );
};
