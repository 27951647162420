import { gql } from "@apollo/client";

export interface ICreateAttraction {
  id: number;
  title: string;
  content: string;
  media: {
    id: string;
    path: string;
  };
  expired: string;
  created: string;
  updated: string;
}

export interface ICreateAttractionData {
  createAttraction: ICreateAttraction;
}

export interface ICreateAttractionInputVars {
  data: {
    title: string;
    content: string;
    media: { connect: { id: number } };
  };
}

export const CREATE_ATTRACTION = gql`
  mutation createAttraction($data: AttractionCreateInput) {
    createAttraction(data: $data) {
      id
      title
      content
      media {
        id
        path
      }
      created
      updated
      expired
    }
  }
`;
