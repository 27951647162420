import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    marginBottom: "16px",
  },
  inputLabel: {
    position: "absolute",
    left: "-4px",
    top: "-18px",
    backgroundColor: "white",
    padding: "4px 8px",
  },
  uploadAreaIcon: {
    // color: theme.palette.divider,
    fontSize: "86px",
    zIndex: 2,
    opacity: 0.5,
    transition: "all 0.3s ease-in-out",
  },
  uploadButton: {
    width: "100%",
    height: "300px",
    padding: 0,
    overflow: "hidden",
    position: "relative",
    border: "1px solid",
    borderColor: theme.palette.divider,
    // borderRadius: '8px',
    "&:hover $uploadAreaIcon": {
      opacity: 0.8,
    },
    "&:hover $removeButton": {
      opacity: 0.8,
    },
  },
  previewImage: {
    width: "100%",
    height: "100%",
    position: "absolute",
    objectFit: "cover",
  },
  removeButton: {
    position: "absolute",
    top: "8px",
    right: "8px",
    zIndex: 2,
    opacity: 0.5,
    transition: "all 0.3s ease-in-out",
  },
  onError: {
    borderColor: theme.palette.error.main,
  },
}));
