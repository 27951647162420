import { createTypedInputs } from "../../utils";

export const initialInputData = createTypedInputs({
  email: {
    value: "",
    label: "Email",
    rules: "email",
    required: true,
  },
  password: {
    value: "",
    type: "password",
    label: "Lozinka",
    required: true,
  },
});
