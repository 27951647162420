import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import { Link as LinkIcon } from "@material-ui/icons";
import { FC, Fragment, useEffect, useRef, useState } from "react";
import { useSlate } from "slate-react";
import { validateForm } from "../../../utils";
import { useForm } from "../../../utils/hooks/useForm";
import { insertLink, isLinkActive } from "../../../utils";
import { initialInputData } from "./LinkButton.inputs";

export const LinkButton: FC = () => {
  const editor = useSlate();
  const { inputFields, setInputFields, inputProps, resetFields } = useForm<
    keyof typeof initialInputData
  >(initialInputData);
  const [openDialog, setOpenDialog] = useState(false);
  const editorSelection = useRef(editor.selection);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    if (openDialog) {
      editorSelection.current = editor.selection;
      // console.log(editorSelection.current);
    }
    // return () => Transforms.deselect(editor);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openDialog]);

  const handleInsertLink = () => {
    const fields = ["link"];
    const valid = validateForm(fields, inputFields);
    if (valid.isValid) {
      // console.log(inputFields.link.value);
      editor.selection = editorSelection.current;
      insertLink(editor, inputFields.link.value as string);
      resetFields();
      handleCloseDialog();
    } else {
      fields.map((item: string) => {
        setInputFields((prevState) => {
          return {
            ...prevState,
            [item]: {
              ...(prevState as any)[item],
              error: true,
            },
          };
        });
        return null;
      });
    }
  };

  return (
    <Fragment>
      <Button
        color={isLinkActive(editor) ? "secondary" : "default"}
        onMouseDown={handleOpenDialog}
      >
        <LinkIcon />
      </Button>
      <Dialog maxWidth='sm' fullWidth open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Unesite link</DialogTitle>
        <DialogContent>
          <TextField
            {...inputProps("link")}
            color="secondary"
            fullWidth
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LinkIcon />
                </InputAdornment>
              ),
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="text" color="primary" onClick={handleCloseDialog}>
            Odustani
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleInsertLink}
          >
            Potvrdi
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};
