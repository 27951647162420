import { gql } from "@apollo/client";

export interface IUpdatePersonInputVars {
  id: string;
  data: {
    password: string;
    passwordReset: string;
  };
}

export const UPDATE_PERSON = gql`
  mutation updatePerson($id: ID!, $data: PersonUpdateInput) {
    updatePerson(id: $id, data: $data) {
      id
    }
  }
`;
