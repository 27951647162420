import { FC } from "react";
import {
  Avatar,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  Typography,
} from "@material-ui/core";
import {
  Edit as EditIcon,
  Info as InfoIcon,
  Title as TitleIcon,
} from "@material-ui/icons";
import { useStyles } from "./CityAdministrationListItem.styles";
import { ICityAdministration } from "../../Apollo/Queries";

interface IProps {
  item: ICityAdministration;
  handleOpenEditDialog: (event: any, item: ICityAdministration) => void;
}

export const CityAdministrationListItem: FC<IProps> = (props) => {
  const { handleOpenEditDialog, item } = props;

  const styles = useStyles();

  const handleEdit = () => {
    handleOpenEditDialog(undefined, props.item);
  };

  return (
    <ListItem divider>
      <ListItemAvatar>
        <Avatar>
          <InfoIcon />
        </Avatar>
      </ListItemAvatar>
      <div className={styles.listTextDiv}>
        <Typography>
          <TitleIcon className={styles.icon} />
          {item.title}
        </Typography>
      </div>
      <ListItemSecondaryAction>
        <IconButton color="secondary" onClick={handleEdit}>
          <EditIcon color="secondary" />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};
