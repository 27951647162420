import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  input: {
    flexBasis: "45%",
  },
  fileUpload: {
    flex: 1,
    marginTop: "16px",
  },
}));
