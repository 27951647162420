import { gql } from "@apollo/client";

export interface IMapMarker {
  id: string;
  title: string;
  description: string;
  link: string;
  longitude: number;
  latitude: number;
  mapMarkerCategory: {
    id: string;
    name: string;
  };
}

export interface IMapMarkerData {
  mapMarker: IMapMarker;
}

export interface IMapMarkersData {
  mapMarkers: IMapMarker[];
}

export interface IMapMarkerVars {
  filter: object;
}

export const ALL_MAP_MARKERS = gql`
  query mapMarkers($filter: FindManyMapMarkerArgs) {
    mapMarkers(filter: $filter) {
      id
      title
      description
      link
      longitude
      latitude
      mapMarkerCategory {
        id
        name
      }
    }
  }
`;
