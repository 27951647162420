import { FC } from "react";
import { withRouter } from "react-router-dom";
import { useStyles } from "./Media.styles";
import { MediaSelectorDialog } from "../../components/MediaSelectorDialog/MediaSelectorDialog";

const Media: FC = () => {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <MediaSelectorDialog
        isNotDialog
        onClose={() => {}}
        onHandlePick={() => {}}
      />
    </div>
  );
};

export default withRouter(Media);
