import { FC, useState } from "react";
import { withRouter } from "react-router-dom";
import { Paper, List, Dialog, Button, Typography } from "@material-ui/core";
import { useStyles } from "./MapMarker.styles";
import { useQuery } from "@apollo/client";
import {
  AddMapMarkerDialog,
  DeleteMapMarkerDialog,
  Error,
  Loading,
  MapMarkerListItem,
} from "../../components";
import {
  ALL_MAP_MARKERS,
  IMapMarker,
  IMapMarkersData,
  IMapMarkerVars,
} from "../../Apollo/Queries";
import { Add as AddIcon } from "@material-ui/icons";

interface IDialogAddState {
  edit: boolean | undefined;
  open: boolean;
  item: IMapMarker | undefined;
}

const MapMarker: FC = () => {
  const styles = useStyles();

  const [dialogAddState, setDialogAddState] = useState<IDialogAddState>({
    edit: undefined,
    open: false,
    item: undefined,
  });
  const [dialogDeleteState, setDialogDeleteState] = useState({
    item: undefined,
    open: false,
  });

  const handleOpenDeleteDialog = (item: any) => {
    setDialogDeleteState({ open: true, item });
  };
  const handleCloseDeleteDialog = () => {
    setDialogDeleteState({ open: false, item: undefined });
  };

  const handleOpenAddDialog = (
    event: any,
    edit?: boolean,
    item?: IMapMarker
  ) => {
    setDialogAddState({ edit, open: true, item });
  };
  const handleCloseAddDialog = () => {
    setDialogAddState({ edit: undefined, open: false, item: undefined });
  };

  const { loading, error, data } = useQuery<IMapMarkersData, IMapMarkerVars>(
    ALL_MAP_MARKERS,
    {
      variables: {
        filter: {
          where: {
            expired: null,
          },
          orderBy: {
            created: "desc",
          },
        },
      },
    }
  );

  if (loading) {
    return <Loading />;
  }
  if (error) {
    // console.error({ error });
    return <Error />;
  }

  return (
    <div className={styles.root}>
      <Button
        onClick={handleOpenAddDialog}
        variant="outlined"
        className={styles.addButton}
        color="secondary"
        endIcon={<AddIcon color="secondary" />}
      >
        Dodaj
      </Button>
      {data?.mapMarkers?.length ? (
        <List component={Paper} disablePadding>
          {data.mapMarkers.map((item: IMapMarker) => {
            return (
              <MapMarkerListItem
                key={item.id}
                item={item}
                handleOpenDeleteDialog={handleOpenDeleteDialog}
                handleOpenEditDialog={handleOpenAddDialog}
              />
            );
          })}
        </List>
      ) : (
        <Typography variant="h6">Nije pronađena niti jedna stavka!</Typography>
      )}
      <Dialog
        open={dialogDeleteState.open}
        onClose={handleCloseDeleteDialog}
        maxWidth="xs"
        fullWidth
      >
        <DeleteMapMarkerDialog
          data={dialogDeleteState.item}
          onClose={handleCloseDeleteDialog}
        />
      </Dialog>
      <Dialog
        open={dialogAddState.open}
        onClose={handleCloseAddDialog}
        maxWidth="md"
        fullWidth
      >
        <AddMapMarkerDialog
          onClose={handleCloseAddDialog}
          edit={dialogAddState.edit}
          item={dialogAddState.item}
        />
      </Dialog>
    </div>
  );
};

export default withRouter(MapMarker);
