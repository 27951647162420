import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "10%",
  },
  paper: {
    width: "40%",
    display: "flex",
    flexDirection: "column",
    padding: "16px",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
  },
  title: {
    textAlign: "center",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "22px",
    },
  },
  inputField: {
    marginBottom: "16px",
  },
  inputDiv: {
    margin: "64px 32px",
  },
}));
