import { createTypedInputs } from "../../utils";

export const initialInputData = createTypedInputs({
  email: {
    value: "",
    label: "Email",
    required: true,
    rules: "email",
  },
});
