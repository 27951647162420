import { FC, useEffect, useState } from "react";
import { Loading } from "./components";
import { MainRouter } from "./routes";
import { setAccessToken } from "./utils";

const App: FC = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_ENDPOINT_REFRESH}`, {
      credentials: "include",
      method: "POST",
    })
      .then(async (x) => {
        const { accessToken } = await x.json();
        setAccessToken(accessToken);
        setLoading(false);
      })
      .catch((err) => {
        // console.log(err);
      });
  }, []);

  if (loading) {
    return <Loading />;
  }

  return <MainRouter />;
};

export default App;
