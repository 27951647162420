import { gql } from "@apollo/client";

export interface ICityAdministration {
  id: string;
  title: string;
  content: string;
  created: Date;
  updated: Date;
  expired: Date;
}

export interface ICityAdministrationsData {
  cityAdministrations: ICityAdministration[];
}

export interface ICityAdministrationsVars {
  filter: object;
}

export const ALL_CITY_ADMINISTRATIONS = gql`
  query cityAdministrations($filter: FindManyCityAdministrationArgs) {
    cityAdministrations(filter: $filter) {
      id
      title
      content
      created
      updated
      expired
    }
  }
`;
