import { Attributes, FC, ReactChild } from "react";
import { Element as SlateElement } from "slate";
import { ImageRte, VideoRte } from "../../";
import { YoutubeRte } from "../YoutubeRte/YoutubeRte";

interface IProps {
  children: ReactChild | ReactChild[];
  attributes: Attributes;
  element: SlateElement;
}
export const SlateRteElement: FC<IProps> = (props) => {
  const { attributes, children, element } = props;

  switch (element.type) {
    case "block-quote":
      return <blockquote {...attributes}>{children}</blockquote>;
    case "bulleted-list":
      return <ul {...attributes}>{children}</ul>;
    case "heading-one":
      return <h1 {...attributes}>{children}</h1>;
    case "heading-two":
      return <h2 {...attributes}>{children}</h2>;
    case "heading-three":
      return <h3 {...attributes}>{children}</h3>;
    case "list-item":
      return <li {...attributes}>{children}</li>;
    case "numbered-list":
      return <ol {...attributes}>{children}</ol>;
    case "link":
      return (
        <a {...attributes} href={element.url}>
          {children}
        </a>
      );
    case "image":
      return <ImageRte {...props} />;
    case "video":
      return <VideoRte {...props} />;
    case "youtube":
      return <YoutubeRte {...props} />;
    default:
      return <p {...attributes}>{children}</p>;
  }
};
