import { FC, useState } from "react";
import { withRouter } from "react-router-dom";
import { Paper, List, Dialog, Typography } from "@material-ui/core";
import { useStyles } from "./CityAdministration.styles";
import { useQuery } from "@apollo/client";
import { Error, Loading, CityAdministrationListItem } from "../../components";
import {
  ALL_CITY_ADMINISTRATIONS,
  ICityAdministrationsData,
  ICityAdministrationsVars,
  ICityAdministration,
} from "../../Apollo/Queries";
import { EditCityAdministrationDialog } from "../../components/EditCityAdministrationDialog/EditCityAdministrationDialog";

interface IDialogEditState {
  open: boolean;
  item: ICityAdministration | undefined;
}

const CityAdministration: FC = () => {
  const styles = useStyles();

  const [dialogEditState, setDialogEditState] = useState<IDialogEditState>({
    open: false,
    item: undefined,
  });

  const handleOpenEditDialog = (event: any, item?: ICityAdministration) => {
    setDialogEditState({ open: true, item });
  };
  const handleCloseEditDialog = () => {
    setDialogEditState({ open: false, item: undefined });
  };

  const { loading, error, data } = useQuery<
    ICityAdministrationsData,
    ICityAdministrationsVars
  >(ALL_CITY_ADMINISTRATIONS, {
    variables: {
      filter: {
        where: {
          expired: null,
        },
        orderBy: {
          created: "desc",
        },
      },
    },
  });

  if (loading) {
    return <Loading />;
  }
  if (error) {
    // console.error({ error });
    return <Error />;
  }

  // console.log(data);

  return (
    <div className={styles.root}>
      {data?.cityAdministrations?.length ? (
        <List component={Paper} disablePadding>
          {data.cityAdministrations.map((item: ICityAdministration) => {
            return (
              <CityAdministrationListItem
                key={item.id}
                item={item}
                handleOpenEditDialog={handleOpenEditDialog}
              />
            );
          })}
        </List>
      ) : (
        <Typography variant="h6">Nije pronađena niti jedna stavka!</Typography>
      )}
      <Dialog
        open={dialogEditState.open}
        onClose={handleCloseEditDialog}
        maxWidth="md"
        fullWidth
      >
        <EditCityAdministrationDialog
          onClose={handleCloseEditDialog}
          item={dialogEditState.item}
        />
      </Dialog>
    </div>
  );
};

export default withRouter(CityAdministration);
