import { FC, useState } from "react";
import { withRouter } from "react-router-dom";
import { Paper, List, Dialog, Typography } from "@material-ui/core";
import { useStyles } from "./Info.styles";
import { useQuery } from "@apollo/client";
import {
  Error,
  Loading,
  GeneralInfoListItem,
  EditGeneralInfoDialog,
} from "../../components";
import {
  ALL_GENERAL_INFOS,
  IGeneralInfo,
  IGeneralInfosData,
  IGeneralInfoVars,
} from "../../Apollo/Queries";

interface IDialogEditState {
  open: boolean;
  item: IGeneralInfo | undefined;
}

const Info: FC = () => {
  const styles = useStyles();

  const [dialogEditState, setDialogEditState] = useState<IDialogEditState>({
    open: false,
    item: undefined,
  });

  const handleOpenEditDialog = (event: any, item?: IGeneralInfo) => {
    setDialogEditState({ open: true, item });
  };
  const handleCloseEditDialog = () => {
    setDialogEditState({ open: false, item: undefined });
  };

  const { loading, error, data } = useQuery<
    IGeneralInfosData,
    IGeneralInfoVars
  >(ALL_GENERAL_INFOS, {
    variables: {
      filter: {
        where: {
          expired: null,
        },
        orderBy: {
          created: "desc",
        },
      },
    },
  });

  if (loading) {
    return <Loading />;
  }
  if (error) {
    // console.error({ error });
    return <Error />;
  }

  // console.log(data);

  return (
    <div className={styles.root}>
      {data?.generalInfos?.length ? (
        <List component={Paper} disablePadding>
          {data.generalInfos.map((item: IGeneralInfo) => {
            return (
              <GeneralInfoListItem
                key={item.id}
                item={item}
                handleOpenEditDialog={handleOpenEditDialog}
              />
            );
          })}
        </List>
      ) : (
        <Typography variant="h6">Nije pronađena niti jedna stavka!</Typography>
      )}
      <Dialog
        open={dialogEditState.open}
        onClose={handleCloseEditDialog}
        maxWidth="md"
        fullWidth
      >
        <EditGeneralInfoDialog
          onClose={handleCloseEditDialog}
          item={dialogEditState.item}
        />
      </Dialog>
    </div>
  );
};

export default withRouter(Info);
