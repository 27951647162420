import { gql } from "@apollo/client";

export interface IAttraction {
  id: string;
  title: string;
  content: string;
  media?: {
    id: string;
    path: string;
  };
  created: Date;
  updated: Date;
  expired: Date;
}

export interface IAttractionsData {
  attractions: IAttraction[];
}

export interface IAttractionsVars {
  filter: object;
}

export const ALL_ATTRACTIONS = gql`
  query attractions($filter: FindManyAttractionArgs) {
    attractions(filter: $filter) {
      id
      title
      content
      media {
        id
        path
      }
      created
      updated
      expired
    }
  }
`;
