import { createTypedInputs } from "../../utils";

export const initialInputData = createTypedInputs({
  password: {
    value: "",
    label: "Trenutna lozinka",
    required: true,
    type: "password",
  },
  passwordReset: {
    value: "",
    label: "Nova lozinku",
    type: "password",
    required: true,
  },
  passwordResetRepeat: {
    value: "",
    label: "Ponovite novu lozinku",
    type: "password",
    required: true,
    errorText: "Lozinka se ne podudara!",
  },
});
