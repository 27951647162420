import { gql } from "@apollo/client";

export interface IUpdateMapMarkerInputVars {
  id: string;
  data: {
    title: string;
    description: string;
    link: string;
    longitude: number;
    latitude: number;
    mapMarkerCategory: { connect: { id: number } };
  };
}

export const UPDATE_MAP_MARKER = gql`
  mutation updateMapMarker($id: ID!, $data: MapMarkerUpdateInput) {
    updateMapMarker(id: $id, data: $data) {
      id
      title
      description
      longitude
      latitude
      link
      mapMarkerCategory {
        id
      }
    }
  }
`;
