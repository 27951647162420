import { createTypedInputs } from "../../utils";

export const initialInputData = createTypedInputs({
  title: {
    value: "",
    label: "Naslov",
    required: true,
    error: false,
  },
  content: {
    value: "",
    label: "Sadržaj",
    required: true,
    error: false,
  },
  contentRte: {
    value: [
      {
        type: "paragraph",
        children: [{ text: "", italic: false }],
      },
    ],
    label: "",
    required: true,
    rules: "slateRte",
  },
  media: {
    value: "",
    label: "Trajanje",
    required: true,
    type: "number",
    error: false,
  },
});
