import { gql } from "@apollo/client";

export interface IUpdatePromotionInputVars {
  id: string;
  data: {
    title: string;
    media: { connect: { id: number } };
    category: { connect: { id: number } };
  };
}

export const UPDATE_PROMOTION = gql`
  mutation updatePromotion($id: ID!, $data: PromotionUpdateInput) {
    updatePromotion(id: $id, data: $data) {
      id
      title
      media {
        id
      }
      category {
        id
      }
    }
  }
`;
