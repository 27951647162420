import { gql } from "@apollo/client";

export interface IUpdateGeneralInfoInputVars {
  id: number;
  data: {
    content: string;
  };
}

export const UPDATE_GENERAL_INFO = gql`
  mutation updateGeneralInfo($id: ID!, $data: GeneralInfoUpdateInput) {
    updateGeneralInfo(id: $id, data: $data) {
      id
      content
    }
  }
`;
