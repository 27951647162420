import { Fragment } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { Dashboard } from "../components";
import { isLoggedIn } from "../utils";
import {
  Admins,
  Attraction,
  CityAdministration,
  Info,
  Login,
  Media,
  Promotion,
  MapMarker,
} from "./";
import LiveCamPage from "./LiveCamPage/LiveCamPage";

const PrivateRoute = ({
  component: Component,
  path,
  exact,
  ...rest
}: {
  component: any;
  path: string;
  exact: boolean;
}) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn() ? (
          <Fragment>
            <Dashboard />
            <Component {...props} />
          </Fragment>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

const PublicRoute = ({
  component: Component,
  restricted,
  path,
  exact,
  ...rest
}: {
  component: any;
  restricted: boolean;
  path: string;
  exact: boolean;
}) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn() && restricted ? (
          <Redirect to="/" />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export const MainRouter = () => {
  return (
    <Router>
      <Switch>
        <PublicRoute restricted={true} component={Login} path="/login" exact />
        <PrivateRoute component={Promotion} path="/" exact />

        <PrivateRoute component={Admins} path="/administratori" exact />
        <PrivateRoute component={Media} path="/mediji" exact />
        <PrivateRoute component={Promotion} path="/promocija" exact />
        <PrivateRoute component={Attraction} path="/atrakcije" exact />
        <PrivateRoute component={Info} path="/informacije" exact />
        <PrivateRoute component={MapMarker} path="/karta" exact />
        <PrivateRoute
          component={CityAdministration}
          path="/gradska-uprava"
          exact
        />
        <Route component={LiveCamPage} path="/live-cam/:id" exact />

        {/* <PublicRoute
          restricted={false}
          component={NotFoundPage}
          path="*"
          exact
        /> */}
      </Switch>
    </Router>
  );
};
