import { FC, Fragment } from "react";
import {
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  DialogActions,
  Dialog,
  LinearProgress,
} from "@material-ui/core";

import { useSnackbar } from "notistack";
import { Reference, useMutation, useQuery } from "@apollo/client";
import {} from "../../Apollo/Mutations";
import {
  ICurrentAdminData,
  CURRENT_ADMIN,
  IPerson,
} from "../../Apollo/Queries";
import { Loading, Error } from "../";
import {
  DELETE_PERSON,
  IDeletePersonData,
  IDeletePersonInputVars,
} from "../../Apollo/Mutations/deletePerson.mutation";

interface IProps {
  onClose: Function;
  data?: IPerson;
}
export const DeleteAdminDialog: FC<IProps> = (props) => {
  const { onClose, data } = props;
  const { enqueueSnackbar } = useSnackbar();

  const { loading, error, data: adminData } = useQuery<ICurrentAdminData>(
    CURRENT_ADMIN,
    { fetchPolicy: "no-cache" }
  );
  const [
    deletePersonMutation,
    { loading: loadingDeleteMutation },
  ] = useMutation<IDeletePersonData, IDeletePersonInputVars>(DELETE_PERSON, {
    onCompleted: () => {
      enqueueSnackbar("Administrator je uspješno obrisan!", {
        variant: "success",
      });
      onClose();
    },
    onError: (err) => {
      // console.error({ err });
      enqueueSnackbar(err.message, {
        variant: "error",
      });
    },
    update(cache, { data }) {
      cache.modify({
        fields: {
          persons(existingPersons: Array<Reference>, { readField }) {
            if (data) {
              return existingPersons.filter(
                (taskRef) => data.deletePerson.id !== readField("id", taskRef)
              );
            }
          },
        },
      });
    },
  });

  const handleDelete = () => {
    if (data) {
      deletePersonMutation({
        variables: {
          id: +data.id,
        },
      });
    }
  };

  if (loading) {
    return <Loading small />;
  }
  if (error) {
    return <Error small />;
  }

  return (
    <Fragment>
      <DialogTitle>Brisanje stavke</DialogTitle>
      <DialogContent>
        <Typography paragraph variant="body2">
          Admin: {data?.email}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="primary" onClick={() => onClose()}>
          Odustani
        </Button>
        <Button
          variant="contained"
          disabled={adminData?.currentAdmin?.id === data?.id}
          color="primary"
          onClick={handleDelete}
        >
          Obriši
        </Button>
      </DialogActions>
      <Dialog
        open={loadingDeleteMutation}
        fullScreen
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
      >
        <LinearProgress color="secondary" />
      </Dialog>
    </Fragment>
  );
};
