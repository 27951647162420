import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  mediaPickerContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  fileUpload: {
    flexBasis: "100%",
    marginTop: "32px",
  },
}));
