import { Button } from "@material-ui/core";
import { LinkOff as LinkOffIcon } from "@material-ui/icons";
import { FC } from "react";
import { useSlate } from "slate-react";
import { isLinkActive, unwrapLink } from "../../../utils";

export const RemoveLinkButton: FC = () => {
  const editor = useSlate();

  return (
    <Button
      color={isLinkActive(editor) ? "secondary" : "default"}
      onMouseDown={(event) => {
        if (isLinkActive(editor)) {
          unwrapLink(editor);
        }
      }}
    >
      <LinkOffIcon />
    </Button>
  );
};
