import { gql } from '@apollo/client';

export interface ICreateMediaInputVars {
  data: object;
}

export const CREATE_MEDIA = gql`
  mutation createMedia($data: MediaCreateInput) {
    createMedia(data: $data) {
      id
      path
    }
  }
`;
