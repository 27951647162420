import { gql } from "@apollo/client";

export interface IUpdateCityAdministrationInputVars {
  id: number;
  data: {
    content: string;
  };
}

export const UPDATE_CITY_ADMINISTRATION = gql`
  mutation updateCityAdministration(
    $id: ID!
    $data: CityAdministrationUpdateInput
  ) {
    updateCityAdministration(id: $id, data: $data) {
      id
      content
    }
  }
`;
