import { FC } from "react";
import { CircularProgress } from "@material-ui/core";
import { useStyles } from "./Loading.styles";

interface IProps {
  small?: boolean;
}

export const Loading: FC<IProps> = (props) => {
  const { small } = props;
  const styles = useStyles();
  return (
    <div className={small ? styles.rootSmall : styles.root}>
      <CircularProgress color="secondary" />
    </div>
  );
};
