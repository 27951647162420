import { FC, Fragment, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
  ALL_MAP_MARKERS,
  ALL_MAP_MARKER_CATEGORIES,
  IMapMarker,
  IMapMarkerCategoriesData,
  IMapMarkerCategory,
  IMapMarkerCategoryVars,
  IMapMarkersData,
} from "../../Apollo/Queries";
import { useStyles } from "./AddMapMarkerDialog.styles";
import { initialInputData } from "./AddMapMarkerDialog.inputs";
import { validateForm } from "../../utils";
import { useSnackbar } from "notistack";
import {
  CREATE_MAP_MARKER,
  ICreateMapMarker,
  ICreateMapMarkerData,
  ICreateMapMarkerInputVars,
  IUpdateMapMarkerInputVars,
  UPDATE_MAP_MARKER,
} from "../../Apollo/Mutations";
import { useForm } from "../../utils/hooks/useForm";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  LinearProgress,
  MenuItem,
  TextField,
} from "@material-ui/core";
import {
  Category as CategoryIcon,
  FileCopy as FileCopyIcon,
  SwapHoriz as SwapHorizIcon,
  Link as LinkIcon,
  Title as TitleIcon,
  SwapVert as SwapVertIcon,
} from "@material-ui/icons";
import { Error, Loading } from "../";

interface IProps {
  onClose: () => void;
  edit: boolean | undefined;
  item: IMapMarker | undefined;
}

export const AddMapMarkerDialog: FC<IProps> = (props) => {
  const { onClose, edit, item } = props;

  const { enqueueSnackbar } = useSnackbar();
  const styles = useStyles();

  const { inputFields, setInputFields, inputProps } = useForm<
    keyof typeof initialInputData
  >(initialInputData);

  const {
    data: dataCategories,
    error: errorCategories,
    loading: loadingCategories,
  } = useQuery<IMapMarkerCategoriesData, IMapMarkerCategoryVars>(
    ALL_MAP_MARKER_CATEGORIES,
    {
      variables: {
        filter: {
          where: {
            expired: null,
          },
        },
      },
    }
  );

  const [
    createMapMarkerMutation,
    { loading: loadingCreateMutation },
  ] = useMutation<ICreateMapMarkerData, ICreateMapMarkerInputVars>(
    CREATE_MAP_MARKER,
    {
      variables: {
        data: {
          title: inputFields.title.value,
          description: inputFields.description.value,
          link: inputFields.link.value,
          longitude: inputFields.longitude.value,
          latitude: inputFields.latitude.value,
          mapMarkerCategory: { connect: { id: +inputFields.category.value } },
        },
      },
      onCompleted: (data) => {
        // console.log("Create data: ", data);
        enqueueSnackbar("Oznaka je uspješno kreirana!", {
          variant: "success",
        });
        onClose();
      },
      onError: (err) => {
        // console.error({ err });
        enqueueSnackbar(err.message, {
          variant: "error",
        });
      },
      update: (cache, { data }) => {
        const existingMapMarkers: IMapMarkersData | null = cache.readQuery({
          query: ALL_MAP_MARKERS,
          variables: {
            filter: {
              where: {
                expired: null,
              },
              orderBy: {
                created: "desc",
              },
            },
          },
        });

        if (data?.createMapMarker) {
          const newMapMarker: ICreateMapMarker = {
            ...data.createMapMarker,
          };
          cache.writeQuery({
            query: ALL_MAP_MARKERS,
            variables: {
              filter: {
                where: {
                  expired: null,
                },
                orderBy: {
                  created: "desc",
                },
              },
            },
            data: {
              mapMarkers: existingMapMarkers?.mapMarkers
                ? [newMapMarker, ...existingMapMarkers.mapMarkers]
                : [newMapMarker],
            },
          });
        }
      },
    }
  );

  const [
    updateMapMarkerMutation,
    { loading: loadingUpdateMutation },
  ] = useMutation<IMapMarker, IUpdateMapMarkerInputVars>(UPDATE_MAP_MARKER, {
    onCompleted: (data) => {
      // console.log("Create data: ", data);
      enqueueSnackbar("Oznaka je uspješno ažurirana", {
        variant: "success",
      });
      onClose();
    },
    onError: (err) => {
      // console.error({ err });
      enqueueSnackbar(err.message, {
        variant: "error",
      });
    },
  });

  useEffect(() => {
    if (edit && item) {
      setInputFields((prevState) => {
        return {
          title: {
            ...prevState.title,
            value: item.title,
          },
          description: {
            ...prevState.description,
            value: item.description,
          },
          link: {
            ...prevState.link,
            value: item.link,
          },
          longitude: {
            ...prevState.longitude,
            value: item.longitude,
          },
          latitude: {
            ...prevState.latitude,
            value: item.latitude,
          },
          category: {
            ...prevState.category,
            value: item.mapMarkerCategory.id,
          },
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit, item]);

  const handleCreateOrEditMapMarker = () => {
    const fields = ["title", "longitude", "latitude", "category"];
    const valid = validateForm(fields, inputFields);
    if (valid.isValid) {
      if (edit && item?.id) {
        updateMapMarkerMutation({
          variables: {
            id: item?.id,
            data: {
              title: inputFields.title.value,
              description: inputFields.description.value,
              link: inputFields.link.value,
              longitude: inputFields.longitude.value,
              latitude: inputFields.latitude.value,
              mapMarkerCategory: {
                connect: { id: +inputFields.category.value },
              },
            },
          },
        });
      } else {
        createMapMarkerMutation();
      }
    } else {
      fields.map((item: string) => {
        setInputFields((prevState) => {
          return {
            ...prevState,
            [item]: {
              ...(prevState as any)[item],
              error: valid.outputData[item].error,
              errorText: valid.outputData[item].errorText,
            },
          };
        });
        return null;
      });
    }
  };

  if (errorCategories) {
    // console.error({ errorCategories });
  }

  return (
    <Fragment>
      <DialogTitle>
        {edit ? `Uređivanje oznake ${item?.title}` : "Dodavanje nove oznake"}
      </DialogTitle>
      <DialogContent className={styles.container}>
        <TextField
          {...inputProps("title")}
          color="secondary"
          variant="outlined"
          className={styles.input}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <TitleIcon />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          {...inputProps("link")}
          color="secondary"
          variant="outlined"
          className={styles.input}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LinkIcon />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          {...inputProps("description")}
          color="secondary"
          variant="outlined"
          margin="normal"
          multiline
          fullWidth
          rowsMax={3}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FileCopyIcon />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          {...inputProps("latitude")}
          color="secondary"
          variant="outlined"
          margin="normal"
          className={styles.input}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SwapHorizIcon />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          {...inputProps("longitude")}
          color="secondary"
          variant="outlined"
          className={styles.input}
          margin="normal"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SwapVertIcon />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          {...inputProps("category")}
          fullWidth
          color="secondary"
          margin="normal"
          variant="outlined"
          select
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <CategoryIcon />
              </InputAdornment>
            ),
          }}
        >
          <MenuItem value="">--Odaberite--</MenuItem>
          {loadingCategories ? (
            <Loading small />
          ) : dataCategories?.mapMarkerCategories?.length ? (
            dataCategories?.mapMarkerCategories?.map(
              (item: IMapMarkerCategory) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              )
            )
          ) : (
            <Error small />
          )}
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="primary" onClick={() => onClose()}>
          Odustani
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleCreateOrEditMapMarker}
        >
          {edit ? "Izmjeni" : "Dodaj"}
        </Button>
      </DialogActions>
      <Dialog
        open={loadingCreateMutation || loadingUpdateMutation}
        fullScreen
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
      >
        <LinearProgress color="secondary" />
      </Dialog>
    </Fragment>
  );
};
