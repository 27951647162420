import { DynamicForm, DynamicFormObject } from "./hooks/useForm";
import { isSlateEmpty } from "./rteUtils";

export type validType = {
  inputData: object;
  isValid: boolean;
};

export function createTypedInputs<
  T extends { [name: string]: DynamicFormObject }
>(input: T): Record<keyof T, DynamicFormObject> {
  return input;
}

export const validateForm = (fields: Array<string>, values: DynamicForm) => {
  const outputData: DynamicForm = {};
  let isValid = true;
  if (fields && fields.length) {
    if (values && Object.keys(values).length) {
      fields.map((field: string) => {
        let invalid = false;
        let errorText = "";
        // Check if field is not empty
        if (!values[field].value) {
          invalid = true;
          isValid = false;
        }

        if (values[field].rules === "slateRte") {
          isValid = !isSlateEmpty(values[field].value);
          // console.log("VF:", isValid);
          if (!isValid) {
            invalid = true;
            isValid = false;
            errorText = "";
          }
        }

        // Check if field is a valid email address
        if (values[field].rules === "email") {
          // if (!values[field].value) {
          //   helperText = "";
          // } else {
          const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
          isValid = pattern.test(values[field].value as string);
          if (!isValid) {
            invalid = true;
            isValid = false;
            errorText = "Neispravna email adresa.";
            // }
          }
        }
        // Check if field is a valid phone number
        if (values[field].rules === "phone") {
          const pattern = /[5,9][1,2,5,7,8,9].{6,7}/;
          isValid = pattern.test(values[field].value as string);
          if (!isValid) {
            invalid = true;
            isValid = false;
            errorText = "Neispravan kontakt broj.";
          }
        }
        outputData[field] = {
          ...values[field],
          error: invalid,
          errorText: errorText ? errorText : values[field].errorText,
        };
        return null;
      });
    }
  }
  return { isValid, outputData };
};
