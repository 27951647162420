import { FC } from "react";
import { withRouter, useHistory } from "react-router-dom";
import { validateForm, setAccessToken } from "../../utils";
import {
  Paper,
  Typography,
  TextField,
  InputAdornment,
  Button,
} from "@material-ui/core";
import { Email as EmailIcon, Lock as LockIcon } from "@material-ui/icons";
import { useStyles } from "./Login.styles";
import { useMutation } from "@apollo/client";
import { ILoginVars, LOGIN } from "../../Apollo/Mutations";
import { useSnackbar } from "notistack";
import { useForm } from "../../utils/hooks/useForm";
import { initialInputData } from "./Login.inputs";

const Login: FC = () => {
  const styles = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { inputFields, setInputFields, inputProps } = useForm<
    keyof typeof initialInputData
  >(initialInputData);

  const [LoginPerson] = useMutation<any, ILoginVars>(LOGIN, {
    onCompleted: (data) => {
      // console.log(data);
      enqueueSnackbar("Uspješna prijava!", {
        variant: "success",
      });
      setAccessToken(data.login.token);
      history.replace("/");
    },
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: "error",
      });
      // console.log({ error });
    },
    variables: {
      data: {
        email: inputFields.email.value,
        password: inputFields.password.value,
      },
    },
    fetchPolicy: "no-cache",
  });

  const handleLoginMutation = () => {
    const fields = ["email", "password"];
    const valid = validateForm(fields, inputFields);
    if (valid.isValid) {
      LoginPerson();
    } else {
      fields.map((item: string) => {
        setInputFields((prevState) => {
          return {
            ...prevState,
            [item]: {
              ...(prevState as any)[item],
              error: valid.outputData[item].error,
              errorText: valid.outputData[item].errorText,
            },
          };
        });
        return null;
      });
    }
  };

  return (
    <div className={styles.root}>
      <Paper className={styles.paper}>
        <Typography className={styles.title} variant="h3">
          Login
        </Typography>
        <div className={styles.inputDiv}>
          <form>
            <TextField
              className={styles.inputField}
              {...inputProps("email")}
              fullWidth
              color="secondary"
              autoComplete="username"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              className={styles.inputField}
              {...inputProps("password")}
              fullWidth
              color="secondary"
              autoComplete="current-password"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon />
                  </InputAdornment>
                ),
              }}
            />
          </form>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleLoginMutation}
          >
            Login
          </Button>
        </div>
      </Paper>
    </div>
  );
};

export default withRouter(Login);
