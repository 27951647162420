import { gql } from "@apollo/client";

export interface IGeneralInfo {
  id: string;
  title: string;
  content: string;
}

export interface IGeneralInfoData {
  generalInfo: IGeneralInfo;
}

export interface IGeneralInfosData {
  generalInfos: IGeneralInfo[];
}

export interface IGeneralInfoVars {
  filter: object;
}

export const ALL_GENERAL_INFOS = gql`
  query generalInfos($filter: FindManyGeneralInfoArgs) {
    generalInfos(filter: $filter) {
      id
      title
      content
    }
  }
`;
