import { gql } from "@apollo/client";

export interface IDeletePerson {
  id: string;
}

export interface IDeletePersonData {
  deletePerson: IDeletePerson;
}

export interface IDeletePersonInputVars {
  id: number;
}

export const DELETE_PERSON = gql`
  mutation deletePerson($id: ID) {
    deletePerson(id: $id) {
      id
    }
  }
`;
