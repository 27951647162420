import { useState } from "react";

export interface DynamicFormObject {
  value: any;
  label: string;
  rules?: "email" | "phone" | "slateRte";
  required?: boolean;
  error?: boolean;
  placeholder?: string;
  helperText?: string;
  errorText?: string;
  type?: "number" | "text" | "password" | "email" | "hidden";
}

export interface DynamicForm {
  [key: string]: DynamicFormObject;
}

interface IInputProps {
  value: string;
  label: string;
  type?: "number" | "text" | "password" | "email" | "hidden";
  required: boolean;
  error?: boolean;
  helperText?: string;
  onChange: (event: any) => void;
}

export const useForm = <T extends string | number | symbol>(
  initialInputData: Record<T, DynamicFormObject>
) => {
  const [inputFields, setInputFields] = useState<Record<T, DynamicFormObject>>(
    initialInputData
  );

  const resetFields = () => {
    setInputFields(initialInputData);
  };

  const setInputField = (inputKey: T, value: any): void => {
    setInputFields((prevState) => {
      return {
        ...prevState,
        [inputKey]: {
          ...prevState[inputKey],
          error: false,
          value: value,
        },
      };
    });
  };

  const inputProps = (inputKey: T): IInputProps => {
    return {
      onChange: (event: any) => {
        event.persist();
        setInputFields((prevState) => {
          return {
            ...prevState,
            [inputKey]: {
              ...prevState[inputKey],
              error: false,
              value: event.target.value,
            },
          };
        });
      },
      value: inputFields[inputKey].value as string,
      label: inputFields[inputKey].label,
      type: inputFields[inputKey].type,
      required: inputFields[inputKey].required ? true : false,
      error: inputFields[inputKey].error,
      helperText:
        inputFields[inputKey].error && inputFields[inputKey].value
          ? inputFields[inputKey].errorText
          : inputFields[inputKey].helperText,
    };
  };

  return {
    inputFields,
    setInputFields,
    inputProps,
    resetFields,
    setInputField,
  } as const;
};
