import { gql } from "@apollo/client";

export interface IDeleteMapMarker {
  id: string;
}

export interface IDeleteMapMarkerData {
  deleteMapMarker: IDeleteMapMarker;
}

export interface IDeleteMapMarkerInputVars {
  id: number;
}

export const DELETE_MAP_MARKER = gql`
  mutation deleteMapMarker($id: ID) {
    deleteMapMarker(id: $id) {
      id
    }
  }
`;
