import { FC, Fragment, useEffect } from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
  ALL_CATEGORIES,
  ALL_PROMOTIONS,
  ICategoriesData,
  ICategoriesVars,
  ICategory,
  IPromotion,
  IPromotionsData,
} from "../../Apollo/Queries";
import { useStyles } from "./AddPromotionDialog.styles";
import { initialInputData } from "./AddPromotionDialog.inputs";
import { validateForm } from "../../utils";
import { useSnackbar } from "notistack";
import {
  CREATE_PROMOTION,
  ICreatePromotion,
  ICreatePromotionData,
  ICreatePromotionInputVars,
  IUpdatePromotionInputVars,
  UPDATE_PROMOTION,
} from "../../Apollo/Mutations";
import { useForm } from "../../utils/hooks/useForm";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  LinearProgress,
  MenuItem,
  TextField,
} from "@material-ui/core";
import {
  Category as CategoryIcon,
  Email as EmailIcon,
} from "@material-ui/icons";
import { FileUpload } from "../FileUpload/FileUpload";
import { Error } from "../Error/Error";
import { Loading } from "../Loading/Loading";

interface IProps {
  onClose: () => void;
  edit: boolean | undefined;
  item: IPromotion | undefined;
}

export const AddPromotionDialog: FC<IProps> = (props) => {
  const { onClose, edit, item } = props;

  const { enqueueSnackbar } = useSnackbar();
  const styles = useStyles();

  const { inputFields, setInputFields, inputProps } = useForm<
    keyof typeof initialInputData
  >(initialInputData);

  const {
    data: dataCategories,
    error: errorCategories,
    loading: loadingCategories,
  } = useQuery<ICategoriesData, ICategoriesVars>(ALL_CATEGORIES, {
    variables: {
      filter: {
        where: {
          expired: null,
        },
      },
    },
  });

  const [
    createPromotionMutation,
    { loading: loadingCreateMutation },
  ] = useMutation<ICreatePromotionData, ICreatePromotionInputVars>(
    CREATE_PROMOTION,
    {
      variables: {
        data: {
          title: inputFields.title.value,
          media: { connect: { id: +inputFields.media.value } },
          category: { connect: { id: +inputFields.category.value } },
        },
      },
      onCompleted: (data) => {
        // console.log("Create data: ", data);
        enqueueSnackbar("Promocija je uspješno kreirana!", {
          variant: "success",
        });
        onClose();
      },
      onError: (err) => {
        // console.error({ err });
        enqueueSnackbar(err.message, {
          variant: "error",
        });
      },
      update: (cache, { data }) => {
        const existingPromotions: IPromotionsData | null = cache.readQuery({
          query: ALL_PROMOTIONS,
          variables: {
            filter: {
              where: {
                expired: null,
              },
              orderBy: {
                created: "desc",
              },
            },
          },
        });

        if (data?.createPromotion) {
          const newPromotion: ICreatePromotion = {
            ...data.createPromotion,
          };
          // console.log(data, existingPromotions);
          cache.writeQuery({
            query: ALL_PROMOTIONS,
            variables: {
              filter: {
                where: {
                  expired: null,
                },
                orderBy: {
                  created: "desc",
                },
              },
            },
            data: {
              promotions: existingPromotions?.promotions
                ? [newPromotion, ...existingPromotions.promotions]
                : [newPromotion],
            },
          });
        }
      },
    }
  );

  const [
    updatePromotionMutation,
    { loading: loadingUpdateMutation },
  ] = useMutation<IPromotion, IUpdatePromotionInputVars>(UPDATE_PROMOTION, {
    onCompleted: (data) => {
      // console.log("Create data: ", data);
      enqueueSnackbar("Promocija je uspješno ažurirana", {
        variant: "success",
      });
      onClose();
    },
    onError: (err) => {
      // console.error({ err });
      enqueueSnackbar(err.message, {
        variant: "error",
      });
    },
  });

  useEffect(() => {
    if (edit && item) {
      setInputFields((prevState) => {
        return {
          title: {
            ...prevState.title,
            value: item.title,
          },
          media: {
            ...prevState.media,
            value: item.media?.id || "",
          },
          category: {
            ...prevState.category,
            value: item.category.id,
          },
        };
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit, item]);

  const handleCreateOrEditAdmin = () => {
    const fields = ["title", "media", "category"];
    const valid = validateForm(fields, inputFields);
    if (valid.isValid) {
      if (edit && item?.id) {
        updatePromotionMutation({
          variables: {
            id: item?.id,
            data: {
              title: inputFields.title.value,
              media: { connect: { id: +inputFields.media.value } },
              category: { connect: { id: +inputFields.category.value } },
            },
          },
        });
      } else {
        createPromotionMutation();
      }
    } else {
      fields.map((item: string) => {
        setInputFields((prevState) => {
          return {
            ...prevState,
            [item]: {
              ...(prevState as any)[item],
              error: valid.outputData[item].error,
              errorText: valid.outputData[item].errorText,
            },
          };
        });
        return null;
      });
    }
  };

  const handleChangeMedia = (id: string) => {
    setInputFields((prevState: any) => {
      return {
        ...prevState,
        media: { ...prevState.media, value: id },
      };
    });
  };

  if (errorCategories) {
    // console.error(errorCategories)
  }

  return (
    <Fragment>
      <DialogTitle>
        {edit
          ? `Uređivanje promocije ${item?.title}`
          : "Dodavanje nove promocije"}
      </DialogTitle>
      <DialogContent className={styles.container}>
        <TextField
          {...inputProps("title")}
          color="secondary"
          variant="outlined"
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EmailIcon />
              </InputAdornment>
            ),
          }}
        />
        <FileUpload
          className={styles.fileUpload}
          onChangeMedia={handleChangeMedia}
          currentImageId={inputFields.media.value as string}
          errored={inputFields.media.error}
          type="image"
        />
        <TextField
          {...inputProps("category")}
          fullWidth
          color="secondary"
          variant="outlined"
          select
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <CategoryIcon />
              </InputAdornment>
            ),
          }}
        >
          <MenuItem value="">--Odaberite--</MenuItem>
          {loadingCategories ? (
            <Loading small />
          ) : dataCategories?.categories?.length ? (
            dataCategories?.categories?.map((item: ICategory) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))
          ) : (
            <Error small />
          )}
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="primary" onClick={() => onClose()}>
          Odustani
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleCreateOrEditAdmin}
        >
          {edit ? "Izmjeni" : "Dodaj"}
        </Button>
      </DialogActions>
      <Dialog
        open={loadingCreateMutation || loadingUpdateMutation}
        fullScreen
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
      >
        <LinearProgress color="secondary" />
      </Dialog>
    </Fragment>
  );
};
