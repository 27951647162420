import {
  Typography,
  IconButton,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Divider,
  Dialog,
} from "@material-ui/core";
import { Fragment, useRef, useState, FC, useEffect } from "react";
import { useStyles } from "./Dashboard.styles";
import { NavLink, useHistory } from "react-router-dom";
import { getAccessToken, setAccessToken } from "../../utils";
import {
  Announcement as AnnouncementIcon,
  Info as InfoIcon,
  LocalFlorist as LocalFloristIcon,
  LocationCity as LocationCityIcon,
  Map as MapIcon,
  Person as PersonIcon,
  PersonRounded as PersonRoundedIcon,
  PhotoLibrary as PhotoLibraryIcon,
} from "@material-ui/icons";
import { useQuery, useMutation } from "@apollo/client";
import { Error, ResetPasswordDialog, Loading } from "../";
import { CURRENT_ADMIN, ICurrentAdminData } from "../../Apollo/Queries";
import { LOGOUT } from "../../Apollo/Mutations";
import { useSnackbar } from "notistack";

export const Dashboard: FC = () => {
  const styles = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(false);
  const [passwordDialog, setPasswordDialog] = useState(false);

  const anchorRef = useRef(null);

  const { loading, error, data } = useQuery<ICurrentAdminData>(CURRENT_ADMIN, {
    fetchPolicy: "no-cache",
  });

  const [logoutMutation] = useMutation(LOGOUT, {
    onCompleted: () => {
      setAccessToken("");
      enqueueSnackbar("Uspješna odjava!", {
        variant: "success",
      });
      history.push("/login");
    },
  });

  const handleSetOpenPasswordDialog = () => {
    handleToggle();
    setPasswordDialog(true);
  };

  const handleSetClosePasswordDialog = () => {
    setPasswordDialog(false);
  };

  const onLogOut = () => {
    logoutMutation({ variables: { data: { token: getAccessToken() } } });
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: any) => {
    // @ts-ignore
    if (anchorRef?.current?.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event: any) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      // @ts-ignore
      anchorRef?.current?.focus();
    }

    prevOpen.current = open;
  }, [open]);

  if (error) {
    // console.log({ error });
    return <Error />;
  }
  if (loading) {
    return <Loading />;
  }

  return (
    <Fragment>
      {process.env.NODE_ENV === "development" && (
        <Typography className={styles.development} variant="h2">
          DEVELOPMENT
        </Typography>
      )}
      <header className={styles.topNavigation}>
        <div className={styles.topNavigationDiv}>
          <Typography className={styles.topTitle} variant="h1">
            Administracija Ogulin
          </Typography>
          <IconButton
            title="Profil"
            ref={anchorRef}
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
          >
            <PersonRoundedIcon
              color={open ? "secondary" : "action"}
              fontSize="large"
            />
          </IconButton>
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={open}
                      id="menu-list-grow"
                      onKeyDown={handleListKeyDown}
                    >
                      <MenuItem disabled>
                        {data && data.currentAdmin && data.currentAdmin.email}
                      </MenuItem>
                      <Divider />
                      <MenuItem onClick={handleSetOpenPasswordDialog}>
                        Izmjena lozinke
                      </MenuItem>
                      <MenuItem onClick={onLogOut}>Odjava</MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
      </header>
      <nav className={styles.root}>
        <img src="./logo528.jpg" width="100%" alt="Ogulin logo" />

        <NavLink
          to="/"
          exact
          className={styles.navLink}
          activeClassName={styles.navLinkActive}
        >
          <AnnouncementIcon className={styles.navLinkIcon} />
          <Typography className={styles.navLinkText}>Promocije</Typography>
        </NavLink>
        <NavLink
          to="/atrakcije"
          exact
          className={styles.navLink}
          activeClassName={styles.navLinkActive}
        >
          <LocalFloristIcon className={styles.navLinkIcon} />
          <Typography className={styles.navLinkText}>Atrakcije</Typography>
        </NavLink>
        <NavLink
          to="/gradska-uprava"
          exact
          className={styles.navLink}
          activeClassName={styles.navLinkActive}
        >
          <LocationCityIcon className={styles.navLinkIcon} />
          <Typography className={styles.navLinkText}>Gradska Uprava</Typography>
        </NavLink>
        <NavLink
          to="/informacije"
          exact
          className={styles.navLink}
          activeClassName={styles.navLinkActive}
        >
          <InfoIcon className={styles.navLinkIcon} />
          <Typography className={styles.navLinkText}>Informacije</Typography>
        </NavLink>
        <NavLink
          to="/administratori"
          exact
          className={styles.navLink}
          activeClassName={styles.navLinkActive}
        >
          <PersonIcon className={styles.navLinkIcon} />
          <Typography className={styles.navLinkText}>Administratori</Typography>
        </NavLink>
        <NavLink
          to="/karta"
          exact
          className={styles.navLink}
          activeClassName={styles.navLinkActive}
        >
          <MapIcon className={styles.navLinkIcon} />
          <Typography className={styles.navLinkText}>Karta</Typography>
        </NavLink>
        <NavLink
          to="/mediji"
          exact
          className={styles.navLink}
          activeClassName={styles.navLinkActive}
        >
          <PhotoLibraryIcon className={styles.navLinkIcon} />
          <Typography className={styles.navLinkText}>Mediji</Typography>
        </NavLink>
      </nav>
      <Dialog
        open={passwordDialog}
        onClose={handleSetClosePasswordDialog}
        maxWidth="xs"
        fullWidth
      >
        {data?.currentAdmin?.id && (
          <ResetPasswordDialog
            adminId={data.currentAdmin.id}
            adminEmail={data.currentAdmin.email}
            onClose={handleSetClosePasswordDialog}
          />
        )}
      </Dialog>
    </Fragment>
  );
};
