import { gql } from "@apollo/client";

export interface IMedia {
  id: string;
  path: string;
  extension: string;
  type: string;
}

export interface IMediaData {
  media: IMedia;
}

export interface IMediasData {
  medias: IMedia[];
}

export interface IMediasVars {
  filter: object;
}

export const ALL_MEDIAS = gql`
  query medias($filter: FindManyMediaArgs) {
    medias(filter: $filter) {
      id
      path
      extension
      type
      created
    }
  }
`;

export interface IMediaVars {
  data: object;
}

export const ONE_MEDIA = gql`
  query media($data: MediaWhereUniqueInput) {
    media(data: $data) {
      id
      path
      extension
      type
      created
    }
  }
`;
