import { createTypedInputs } from "../../utils";

export const initialInputData = createTypedInputs({
  content: {
    value: "",
    label: "Sadržaj",
    required: true,
  },
  contentRte: {
    value: [
      {
        type: "paragraph",
        children: [{ text: "", italic: false }],
      },
    ],
    label: "",
    required: true,
    rules: "slateRte",
  },
});
