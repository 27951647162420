import { gql } from "@apollo/client";
import { IMedia } from "./media.query";

export interface IPromotion {
  id: string;
  title: string;
  media?: IMedia;
  category: {
    id: string;
    name: string;
  };
  created: Date;
  updated: Date;
  expired: Date;
}

export interface IPromotionData {
  promotion: IPromotion;
}

export interface IPromotionsData {
  promotions: IPromotion[];
}

export interface IPromotionVars {
  filter: any;
}

export const ALL_PROMOTIONS = gql`
  query promotions($filter: FindManyPromotionArgs) {
    promotions(filter: $filter) {
      id
      title
      media {
        id
        path
        extension
        type
      }
      category {
        id
        name
      }
      created
      updated
      expired
    }
  }
`;
