import { FC } from "react";
import { withRouter, useParams } from "react-router-dom";
import { useStyles } from "./LiveCamPage.styles";

const LiveCamPage: FC = () => {
  const styles = useStyles();
  let { id } = useParams<{ id: string }>();

  return (
    <div className={styles.root}>
      <div className={styles.rightOverlay} />
      <iframe
        marginWidth={0}
        marginHeight={0}
        src={`https://www.whatsupcams.com/wgt/hr_ogulin${id}`}
        scrolling="no"
        frameBorder="0"
        title="ogulin"
        className={styles.iframe}
      />
      <div className={styles.leftOverlay} />
    </div>
  );
};

export default withRouter(LiveCamPage);
