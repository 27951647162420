import { FC, Fragment } from "react";
import {
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@material-ui/core";
import {
  Category as CategoryIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Image as ImageIcon,
  Title as TitleIcon,
  VideoLibrary as VideoLibraryIcon,
  Error as ErrorIcon,
} from "@material-ui/icons";
import { useStyles } from "./PromotionListItem.styles";
import { IPromotion } from "../../Apollo/Queries";

interface IItem {
  id: string;
  title: string;
}

interface IProps {
  item: IPromotion;
  handleOpenDeleteDialog: (item: IItem) => void;
  handleOpenEditDialog: (event: any, edit: boolean, item: IPromotion) => void;
}

export const PromotionListItem: FC<IProps> = (props) => {
  const { handleOpenDeleteDialog, handleOpenEditDialog } = props;
  const { title, id, media, category } = props.item;

  const styles = useStyles();

  const handleDelete = () => {
    handleOpenDeleteDialog({ id, title });
  };

  const handleEdit = () => {
    handleOpenEditDialog(undefined, true, props.item);
  };

  return (
    <ListItem divider className={styles.root}>
      <ListItemAvatar className={styles.mediaHolder}>
        {media?.type === "video" ? (
          <Fragment>
            <video
              preload="metadata"
              controls={false}
              src={`${process.env.REACT_APP_BUCKET_ENDPOINT}${media?.path}`}
              className={styles.image}
            />
            <VideoLibraryIcon className={styles.mediaIcon} color="primary" />
          </Fragment>
        ) : (
          <Fragment>
            {media?.path && (
              <img
                className={styles.image}
                src={`${process.env.REACT_APP_BUCKET_ENDPOINT}${media?.path}`}
                alt=""
              />
            )}
            {media?.path ? (
              <ImageIcon className={styles.mediaIcon} color="primary" />
            ) : (
              <ErrorIcon className={styles.mediaIcon} color="primary" />
            )}
          </Fragment>
        )}
      </ListItemAvatar>
      <ListItemText disableTypography className={styles.listTextDiv}>
        <Typography>
          <TitleIcon className={styles.icon} />
          {title}
        </Typography>
        <Typography>
          <CategoryIcon className={styles.icon} />
          {category.name}
        </Typography>
      </ListItemText>
      <ListItemSecondaryAction>
        <IconButton color="secondary" onClick={handleEdit}>
          <EditIcon color="secondary" />
        </IconButton>
        <IconButton color="secondary" onClick={handleDelete}>
          <DeleteIcon color="secondary" />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};
