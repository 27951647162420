import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    margin: "36px",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100vh",
    color: "#999",
  },
  listTextDiv: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    "& > p": {
      flex: 1,
    },
  },
  icon: {
    verticalAlign: "middle",
    marginRight: "8px",
  },
}));
