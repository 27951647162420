import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    width: "100vw",
    position: "relative",
  },
  iframe: {
    height: "100vh",
    width: "100vw",
    lineHeight: 0,
    display: "block",
  },
  rightOverlay: {
    top: 0,
    bottom: 0,
    right: 0,
    left: "80%",
    position: "absolute",
  },
  leftOverlay: {
    top: 0,
    bottom: 0,
    left: 0,
    right: "80%",
    position: "absolute",
  },
}));
