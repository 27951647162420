import { FC, Fragment } from "react";
import {
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  DialogActions,
  Dialog,
  LinearProgress,
} from "@material-ui/core";

import { useSnackbar } from "notistack";
import { Reference, useMutation } from "@apollo/client";
import {
  DELETE_ATTRACTION,
  IDeleteAttractionData,
  IDeleteAttractionInputVars,
} from "../../Apollo/Mutations";

interface IProps {
  onClose: Function;
  data?: {
    id: string;
    title: string;
  };
}
export const DeleteAttractionDialog: FC<IProps> = (props) => {
  const { onClose, data } = props;
  const { enqueueSnackbar } = useSnackbar();

  const [
    deleteAttractionMutation,
    { loading: loadingDeleteMutation },
  ] = useMutation<IDeleteAttractionData, IDeleteAttractionInputVars>(
    DELETE_ATTRACTION,
    {
      onCompleted: () => {
        enqueueSnackbar("Atrakcija je uspješno obrisana!", {
          variant: "success",
        });
        onClose();
      },
      onError: (err) => {
        // console.error({ err });
        enqueueSnackbar(err.message, {
          variant: "error",
        });
      },
      update(cache, { data }) {
        cache.modify({
          fields: {
            attractions(existingAttractions: Array<Reference>, { readField }) {
              if (data) {
                return existingAttractions.filter(
                  (taskRef) =>
                    data.deleteAttraction.id !== readField("id", taskRef)
                );
              }
            },
          },
        });
      },
    }
  );

  const handleDelete = () => {
    if (data) {
      deleteAttractionMutation({
        variables: {
          id: +data.id,
        },
      });
    }
  };

  return (
    <Fragment>
      <DialogTitle>Brisanje stavke</DialogTitle>
      <DialogContent>
        <Typography paragraph variant="body2">
          Promocija: {data?.title}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="primary" onClick={() => onClose()}>
          Odustani
        </Button>
        <Button variant="contained" color="primary" onClick={handleDelete}>
          Obriši
        </Button>
      </DialogActions>
      <Dialog
        open={loadingDeleteMutation}
        fullScreen
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
      >
        <LinearProgress color="secondary" />
      </Dialog>
    </Fragment>
  );
};
