import { FC, Fragment, useEffect, useRef } from "react";
import { useMutation } from "@apollo/client";
import { ICityAdministration } from "../../Apollo/Queries";
import { useStyles } from "./EditCityAdministrationDialog.styles";
import { initialInputData } from "./EditCityAdministrationDialog.inputs";
import { isSlateEmpty, validateForm } from "../../utils";
import { useSnackbar } from "notistack";
import {
  IUpdateCityAdministrationInputVars,
  UPDATE_CITY_ADMINISTRATION,
} from "../../Apollo/Mutations";
import { useForm } from "../../utils/hooks/useForm";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
} from "@material-ui/core";
import { RichTextEditor } from "..";
import { Descendant, createEditor } from "slate";
import { deserialize, serialize, withMedia, withLinks } from "../../utils";
import { withHistory } from "slate-history";
import { withReact } from "slate-react";

interface IProps {
  onClose: () => void;
  item: ICityAdministration | undefined;
}

export const EditCityAdministrationDialog: FC<IProps> = (props) => {
  const { onClose, item } = props;

  const { enqueueSnackbar } = useSnackbar();
  const styles = useStyles();

  const { inputFields, setInputFields, setInputField } = useForm<
    keyof typeof initialInputData
  >(initialInputData);

  const editorRef = useRef(null);
  if (!editorRef.current)
    //@ts-ignore
    editorRef.current = withMedia(
      withLinks(withHistory(withReact(createEditor())))
    );
  const editor = editorRef.current;

  const handleSetRteValue = (value: Descendant[]) => {
    setInputField("contentRte", value);
  };

  const [
    updateCityAdministrationMutation,
    { loading: loadingUpdateMutation },
  ] = useMutation<ICityAdministration, IUpdateCityAdministrationInputVars>(
    UPDATE_CITY_ADMINISTRATION,
    {
      onCompleted: (data) => {
        // console.log("Create data: ", data);
        enqueueSnackbar("Gradska administracija je uspješno ažurirana", {
          variant: "success",
        });
        onClose();
      },
      onError: (err) => {
        // console.error({ err });
        enqueueSnackbar(err.message, {
          variant: "error",
        });
      },
    }
  );

  useEffect(() => {
    if (item) {
      setInputFields((prevState) => {
        return {
          ...prevState,
          content: { ...prevState.content, value: item.content },
        };
      });
      const document = new DOMParser().parseFromString(
        item.content || "<p></p>",
        "text/html"
      );
      // console.log(deserialize(document.body));
      handleSetRteValue(
        //@ts-ignore
        item.content
          ? deserialize(document.body)
          : [
              {
                type: "paragraph",
                children: [{ text: "" }],
              },
            ]
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  const handleOnBlurRte = () => {
    //@ts-ignore
    setInputField("content", serialize(editor));
  };

  const handleEditInformation = () => {
    const fields = ["contentRte"];
    const valid = validateForm(fields, inputFields);

    if (valid.isValid && item?.id) {
      updateCityAdministrationMutation({
        variables: {
          id: +item.id,
          data: {
            content: inputFields.content.value as string,
          },
        },
      });
    } else {
      fields.map((item: string) => {
        if (item === "contentRte") {
          setInputFields((prevState) => {
            return {
              ...prevState,
              [item]: {
                ...(prevState as any)[item],
                error: isSlateEmpty(inputFields.contentRte.value),
                helperText: valid.outputData[item].helperText,
              },
            };
          });
        } else {
          setInputFields((prevState) => {
            return {
              ...prevState,
              [item]: {
                ...(prevState as any)[item],
                error: valid.outputData[item].error,
                helperText: valid.outputData[item].helperText,
              },
            };
          });
        }
        return null;
      });
    }
  };

  return (
    <Fragment>
      <DialogTitle>Uređivanje {item?.title}</DialogTitle>
      <DialogContent className={styles.container}>
        <RichTextEditor
          editor={editor}
          value={inputFields.contentRte.value}
          placeholder="Unesite tekst..."
          handleSetValue={handleSetRteValue}
          handleOnBlur={handleOnBlurRte}
          errored={inputFields.contentRte.error}
        />
      </DialogContent>

      <DialogActions>
        <Button variant="text" color="primary" onClick={onClose}>
          Odustani
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleEditInformation}
        >
          Izmjeni
        </Button>
      </DialogActions>
      <Dialog
        open={loadingUpdateMutation}
        fullScreen
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
      >
        <LinearProgress color="secondary" />
      </Dialog>
    </Fragment>
  );
};
