import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    position: "fixed",
    // paddingTop: "64px",
    width: "250px",
    backgroundColor: "#F0F0F0",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    top: 0,
  },
  topNavigation: {
    width: "calc(100% - 250px)",
    backgroundColor: theme.palette.primary.main,
    position: "fixed",
    padding: "8px",
    right: 0,
    top: 0,
    zIndex: 2,
    boxShadow: "0 4px 4px -2px #00000077",
  },
  development: {
    position: "fixed",
    zIndex: 2,
    bottom: "16px",
    right: "10%",
    opacity: 0.4,
    color: theme.palette.grey[600],
    pointerEvents: "none",
  },
  topNavigationDiv: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: "64px",
  },
  button: { borderRadius: 0 },
  topTitle: {
    color: "#fff",
    marginLeft: "64px",
    fontSize: "26px",
  },
  title: {
    width: "192px",
    textAlign: "center",
    margin: "32px 0 64px 0",
    fontSize: "22px",
  },
  navLink: {
    color: "white",
    textDecoration: "none",
    width: "100%",
    padding: "0 8px 0 18px",
    alignItems: "center",
    display: "flex",
    backgroundColor: theme.palette.primary.main,
    height: "38px",
    flex: "0 0 auto",
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },

  navLinkActive: {
    color: "white",
    textDecoration: "none",
    height: "38px",
    width: "100%",
    padding: "0 8px 0 18px",
    alignItems: "center",
    flex: "0 0 auto",
    display: "flex",
    backgroundColor: theme.palette.primary.dark,
    transition: "all 0.2s ease-in-out",
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  navLinkIcon: {
    marginRight: "16px",
    color: "#888",
    fontSize: "22px !important",
  },
  navLinkText: {
    paddingTop: "4px",
    fontSize: "14px !important",
  },
}));
