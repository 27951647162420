import { createMuiTheme } from "@material-ui/core/styles";

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#183661", // Blue
    },
    secondary: {
      main: "#DA2028", //FBB040
    },
    text: {
      primary: "#555",
    },
  },
  typography: {
    fontFamily: "atkinson",
  },
});
