import { gql } from "@apollo/client";

export interface IMapMarkerCategory {
  id: string;
  name: string;
}

export interface IMapMarkerCategoryData {
  mapMarkerCategory: IMapMarkerCategory;
}

export interface IMapMarkerCategoriesData {
  mapMarkerCategories: IMapMarkerCategory[];
}

export interface IMapMarkerCategoryVars {
  filter: object;
}

export const ALL_MAP_MARKER_CATEGORIES = gql`
  query mapMarkerCategories($filter: FindManyMapMarkerCategoryArgs) {
    mapMarkerCategories(filter: $filter) {
      id
      name
    }
  }
`;
