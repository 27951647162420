import { TokenRefreshLink } from "apollo-link-token-refresh";
import { getAccessToken, setAccessToken } from "../utils";
import jwtDecode from "jwt-decode";
import {
  InMemoryCache,
  ApolloLink,
  Observable,
  ApolloClient,
  HttpLink,
} from "@apollo/client";

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        medias: {
          merge(existing, incoming) {
            return incoming;
          },
        },
        // blogPosts: {
        //   merge(existing, incoming) {
        //     return incoming;
        //   },
        // },
      },
    },
  },
});

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable((observer) => {
      let handle: any;
      Promise.resolve(operation)
        .then((operation) => {
          const accessToken = getAccessToken();
          if (accessToken) {
            operation.setContext({
              headers: {
                authorization: `bearer ${accessToken}`,
              },
            });
          }
        })
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) handle.unsubscribe();
      };
    })
);

export const apolloClient = new ApolloClient({
  link: ApolloLink.from([
    new TokenRefreshLink({
      accessTokenField: "accessToken",
      isTokenValidOrUndefined: () => {
        // console.log('CHECKING TOKEN');
        const token = getAccessToken();

        if (!token) {
          return true;
        }

        try {
          //@ts-ignore
          const { exp } = jwtDecode(token);
          if (Date.now() >= exp * 1000) {
            return false;
          } else {
            return true;
          }
        } catch {
          return false;
        }
      },
      fetchAccessToken: () => {
        // console.log("FETCH");
        return fetch(`${process.env.REACT_APP_API_ENDPOINT_REFRESH}`, {
          method: "POST",
          credentials: "include",
        });
      },
      handleFetch: (accessToken) => {
        // console.log("HANDLING FETCH");
        setAccessToken(accessToken);
      },
      handleError: (err) => {
        // console.warn("Your refresh token is invalid. Try to relogin");
        // console.error(err);
        setAccessToken("");
      },
    }),
    requestLink,
    new HttpLink({
      uri: process.env.REACT_APP_API_ENDPOINT_GRAPHQL,
      credentials: "include",
    }),
  ]),
  cache,
});
