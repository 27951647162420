import {
  Button,
  ButtonBase,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  Paper,
  Typography,
} from "@material-ui/core";
import { useStyles } from "./MediaSelectorDialog.styles";
import { FC, Fragment, useRef, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { ALL_MEDIAS, IMediasData, IMediasVars } from "../../Apollo/Queries";
import {
  CREATE_MEDIA,
  DELETE_MEDIA,
  ICreateMediaInputVars,
  IDeleteMediaInputVars,
} from "../../Apollo/Mutations";
import {
  AspectRatio as AspectRatioIcon,
  Delete as DeleteIcon,
  Publish as PublishIcon,
} from "@material-ui/icons";
import { useSnackbar } from "notistack";
import { Loading, Error as ErrorComponent } from "../";
//@ts-ignore
import bytes from "bytes";

interface IProps {
  isNotDialog?: boolean;
  onClose: () => void;
  onHandlePick: (id: string, path: string) => void;
  type?: "image" | "video";
}

export const MediaSelectorDialog: FC<IProps> = (props) => {
  const { onClose, onHandlePick, isNotDialog, type } = props;

  const styles = useStyles();
  const inputRef = useRef<HTMLInputElement>(null);
  const { enqueueSnackbar } = useSnackbar();

  const [onDragEnterToggle, setOnDragEnterToggle] = useState(false);
  const [previewDialog, setPreviewDialog] = useState({
    open: false,
    path: "",
  });
  const [deleteDialog, setDeleteDialog] = useState({
    open: false,
    id: "",
    path: "",
  });

  const handleOpenDeleteDialog = (id: string, path: string) => {
    setDeleteDialog({ open: true, id, path });
  };

  const handleCloseDeleteDialog = () => {
    setDeleteDialog({ open: false, id: "", path: "" });
  };

  const handleOpenPreviewDialog = (path: string) => {
    setPreviewDialog({ open: true, path });
  };

  const handleClosePreviewDialog = () => {
    setPreviewDialog({ open: false, path: "" });
  };

  const { data, loading, error } = useQuery<IMediasData, IMediasVars>(
    ALL_MEDIAS,
    {
      variables: {
        filter: {
          orderBy: { created: "desc" },
        },
      },
    }
  );

  const [createMediaMutation, { loading: loadingMutation }] = useMutation<
    any,
    ICreateMediaInputVars
  >(CREATE_MEDIA, {
    onCompleted: (data) => {
      enqueueSnackbar(`Uspješno učitavanje: ${data.createMedia.path}`, {
        variant: "success",
      });
    },
    onError: (err) => {
      console.warn("Error: ", { err });
      enqueueSnackbar(`Neuspješno učitavanje: ${err.message}`, {
        variant: "error",
      });
    },
    update: (cache, data) => {
      const existingMedias: IMediasData | null = cache.readQuery({
        query: ALL_MEDIAS,
      });
      // console.log("EXISTING MEDIAS", existingMedias);
      // console.log("DATA", data.data);
      // console.log(cache);
      const newMedia = data.data.createMedia;
      cache.writeQuery({
        query: ALL_MEDIAS,
        variables: {
          filter: {
            orderBy: {
              created: "desc",
            },
          },
        },
        data: {
          medias: existingMedias?.medias
            ? [newMedia, ...existingMedias.medias]
            : [newMedia],
        },
      });
    },
  });

  const [deleteMediaMutation, { loading: loadingMutationDelete }] = useMutation<
    any,
    IDeleteMediaInputVars
  >(DELETE_MEDIA, {
    onCompleted: (data) => {
      // console.log(data);
      enqueueSnackbar(`Medij je uspješno obrisan: ${data.deleteMedia.path}!`, {
        variant: "success",
      });
      handleCloseDeleteDialog();
    },
    onError: (err) => {
      // console.error(err);
      enqueueSnackbar("Greška kod brisanja medija!", {
        variant: "error",
      });
    },
  });

  const toBase64 = (file: Blob) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const validateFiles = (file: File) => {
    const validTypes = ["image/jpeg", "image/jpg", "image/png", "video/mp4"];

    if (validTypes.indexOf(file.type) === -1) {
      enqueueSnackbar(
        `Krivi format medija: ${file.type || "Nepoznati format"}`,
        {
          variant: "error",
        }
      );
      return false;
    } else if (file.type !== "video/mp4" && file.size > 512000 * 20) {
      enqueueSnackbar(
        `Medij je preveliki: ${bytes(file.size)}! Mora biti manji od ${bytes(
          512000 * 20
        )}`,
        {
          variant: "error",
        }
      );
      return false;
    }
    return true;
  };
  const handleUpload = async (file: File) => {
    // console.log(file.type.split("/")[0]);
    if (file.type === "video/mp4") {
      const blob = await toBase64(file).catch((e) => Error(e));
      createMediaMutation({
        variables: {
          data: {
            name: file.name,
            blob: blob,
            type: file.type.split("/")[0],
            extension: file.type.split("/")[1],
          },
        },
      });
      // };
    } else {
      const blob = await toBase64(file).catch((e) => Error(e));
      if (blob instanceof Error) {
        // console.log("Error: ", blob.message);
        return;
      }
      createMediaMutation({
        variables: {
          data: {
            name: file.name,
            blob: blob,
            type: file.type.split("/")[0],
            extension: file.type.split("/")[1],
          },
        },
      });
    }
  };

  const handleManualInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    // console.log("Upload");
    if (event.target?.files?.length) {
      const files = event.target.files;
      for (let i = 0; i < files.length; i++) {
        if (validateFiles(files[i])) {
          handleUpload(files[i]);
        } else {
        }
      }
    }
    event.target.value = "";
  };

  const handleFileDrop = (files: FileList) => {
    for (let i = 0; i < files.length; i++) {
      if (validateFiles(files[i])) {
        handleUpload(files[i]);
      } else {
      }
    }
  };

  const handleOnDragEnter = (event: React.DragEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setOnDragEnterToggle(true);
  };

  const handleOnDragLeave = (event: React.DragEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setOnDragEnterToggle(false);
  };

  const handleOnDragOver = (event: React.DragEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setOnDragEnterToggle(true);
  };

  const handleOnDragDrop = (event: React.DragEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files.length) {
      handleFileDrop(files);
    }
    setOnDragEnterToggle(false);
  };

  const handleOnClick = () => {
    if (inputRef) {
      //@ts-ignore
      inputRef.current.click();
    }
  };

  const handleDeleteImage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    event.preventDefault();
    // console.log(deleteDialog.id);
    deleteMediaMutation({
      variables: {
        data: {
          id: +deleteDialog.id,
          path: deleteDialog.path,
        },
      },
      update(cache) {
        cache.modify({
          fields: {
            medias(existingMedias, { readField }) {
              return existingMedias.filter(
                (taskRef: any) => deleteDialog.id !== readField("id", taskRef)
              );
            },
          },
        });
      },
    });
  };

  const onClick = (event: any, id: string, path: string) => {
    onHandlePick(id, path);
  };

  const DialogOrNotComponent = isNotDialog ? "div" : DialogContent;

  const displayMedia = () => {
    return data!.medias.reduce((all, item, index) => {
      if (
        type &&
        !item.path.match(
          type === "video" ? /(.*?).(mp4)$/ : /(.*?).(png|jpg|jpeg)$/
        )
      ) {
        return all;
      }
      return all.concat(
        //@ts-ignore
        <Paper className={styles.mediaContainer} key={item.id}>
          <ButtonBase
            className={styles.button}
            focusRipple
            onClick={(event) => {
              onClick(event, item.id, item.path);
            }}
          >
            {item.path.match(/(.*?).(mp4)$/) ? (
              <video
                preload="metadata"
                src={`${process.env.REACT_APP_BUCKET_ENDPOINT}${item.path}`}
                className={styles.image}
              />
            ) : (
              <img
                src={`${process.env.REACT_APP_BUCKET_ENDPOINT}${item.path}`}
                alt=""
                className={styles.image}
              />
            )}
            <Typography className={styles.imageTitle} variant="overline">
              {item.path}
            </Typography>
          </ButtonBase>
          <IconButton
            color="secondary"
            className={styles.deleteIcon}
            onClick={() => handleOpenDeleteDialog(item.id, item.path)}
          >
            <DeleteIcon />
          </IconButton>
          <IconButton
            color="secondary"
            className={styles.expandIcon}
            onClick={() => handleOpenPreviewDialog(item.path)}
          >
            <AspectRatioIcon />
          </IconButton>
        </Paper>
      );
    }, [] as Element[]);
  };

  return (
    <Fragment>
      {!isNotDialog && <DialogTitle>Mediji</DialogTitle>}
      <DialogOrNotComponent>
        <Button
          variant={onDragEnterToggle ? "text" : "outlined"}
          color={onDragEnterToggle ? "secondary" : "inherit"}
          onDragOver={handleOnDragOver}
          onDragEnter={handleOnDragEnter}
          onDragLeave={handleOnDragLeave}
          onDrop={handleOnDragDrop}
          className={
            onDragEnterToggle ? styles.uploadButtonActive : styles.uploadButton
          }
          onClick={handleOnClick}
        >
          {loadingMutation ? (
            <Loading small />
          ) : (
            <PublishIcon className={styles.uploadAreaIcon} />
          )}
        </Button>
        <input
          ref={inputRef}
          multiple={true}
          accept="image/jpg,image/png,video/mp4"
          type="file"
          className={styles.hidden}
          tabIndex={-1}
          onChange={handleManualInput}
        />
        <div className={styles.container}>
          {data?.medias?.length ? (
            loading ? (
              <Loading />
            ) : error ? (
              <ErrorComponent />
            ) : (
              displayMedia()
            )
          ) : (
            <Typography variant="h6">
              Nije pronađen niti jedan medij!
            </Typography>
          )}
        </div>
        <Dialog open={deleteDialog.open} onClose={handleCloseDeleteDialog}>
          <DialogTitle>
            Jeste li sigurni da želite obrisati ovaj medij?
          </DialogTitle>
          <DialogContent>
            <Typography>Ne možete poništiti ovu akciju!</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDeleteDialog} color="primary">
              Odustani
            </Button>
            <Button
              onClick={handleDeleteImage}
              variant="contained"
              color="primary"
            >
              Obriši
            </Button>
          </DialogActions>
          <Dialog
            open={loadingMutationDelete || loadingMutation}
            onClose={undefined}
            fullScreen
            PaperProps={{
              style: {
                backgroundColor: "transparent",
                boxShadow: "none",
              },
            }}
          >
            <LinearProgress color="secondary" />
          </Dialog>
        </Dialog>
      </DialogOrNotComponent>
      {!isNotDialog && (
        <DialogActions>
          <Button variant="outlined" color="primary" onClick={onClose}>
            Zatvori
          </Button>
        </DialogActions>
      )}

      <Dialog
        open={previewDialog.open}
        onClose={handleClosePreviewDialog}
        fullScreen
        PaperProps={{
          style: {
            backgroundColor: "#222",
            boxShadow: "none",
          },
        }}
      >
        <DialogTitle className={styles.previewTitle}>
          {previewDialog.path}
        </DialogTitle>
        <DialogContent>
          {previewDialog.path?.match(/(.*?).(mp4)$/) ? (
            <video
              src={`${process.env.REACT_APP_BUCKET_ENDPOINT}${previewDialog.path}`}
              className={styles.preview}
              controls={true}
            />
          ) : (
            <img
              src={`${process.env.REACT_APP_BUCKET_ENDPOINT}${previewDialog.path}`}
              alt=""
              className={styles.preview}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleClosePreviewDialog}
          >
            Zatvori
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};
