import { gql } from "@apollo/client";

export interface IPerson {
  id: string;
  email: string;
}

export interface IPersonData {
  person: IPerson;
}

export interface IPersonsData {
  persons: IPerson[];
}

export interface IPersonVars {
  filter: object;
}

export const ALL_PERSONS = gql`
  query persons($filter: FindManyPersonArgs) {
    persons(filter: $filter) {
      id
      email
    }
  }
`;
