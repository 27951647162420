import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  toolbar: {
    border: `1px solid ${theme.palette.grey[300]}`,
    backgroundColor: theme.palette.grey[100],
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
    display: "flex",
    flexWrap: "wrap",
    "& > button": {
      // flex: 1,
    },
    "& > hr": {
      width: "100%",
    },
  },
}));
