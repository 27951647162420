import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  imageBlock: {
    display: "block",
    maxWidth: "100%",
    maxHeight: "20em",
  },
  imageBlockActive: {
    boxShadow: "0 0 0 3px #B4D5FF",
  },
}));
