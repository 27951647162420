import { Attributes, FC, ReactChild } from "react";
import { useFocused, useSelected } from "slate-react";
import { useStyles } from "./ImageRte.styles";
import { Element as SlateElement } from "slate";

interface IProps {
  attributes: Attributes;
  children: ReactChild | ReactChild[];
  element: SlateElement;
}

export const ImageRte: FC<IProps> = ({ attributes, children, element }) => {
  const selected = useSelected();
  const focused = useFocused();
  const styles = useStyles();

  return (
    <div {...attributes}>
      <div contentEditable={false}>
        <img
          alt=""
          src={element.url}
          className={`${styles.imageBlock} ${
            selected && focused ? styles.imageBlockActive : ""
          }`}
        />
      </div>
      {children}
    </div>
  );
};
