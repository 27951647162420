import { gql } from "@apollo/client";

export interface IDeletePromotion {
  id: string;
}

export interface IDeletePromotionData {
  deletePromotion: IDeletePromotion;
}

export interface IDeletePromotionInputVars {
  id: number;
}

export const DELETE_PROMOTION = gql`
  mutation deletePromotion($id: ID) {
    deletePromotion(id: $id) {
      id
    }
  }
`;
