import { getAccessToken } from "./accessToken";

export const isLoggedIn = () => {
  const token = getAccessToken();
  if (token) {
    return true;
  } else {
    return false;
  }
};
