import { gql } from '@apollo/client';

export interface ICategory {
  id: string;
  name: boolean;
}

export interface ICategoryData {
  category: ICategory;
}

export interface ICategoriesData {
  categories: ICategory[];
}

export interface ICategoriesVars {
  filter: object;
}

export const ALL_CATEGORIES = gql`
  query categories($filter: FindManyCategoryArgs) {
    categories(filter: $filter) {
      id
      name
    }
  }
`;
