import { gql } from "@apollo/client";

export interface IDeleteMediaInputVars {
  data: {
    id: number;
    path: string;
  };
}

export const DELETE_MEDIA = gql`
  mutation deleteMedia($data: MediaWhereUniqueInput!) {
    deleteMedia(data: $data) {
      id
      path
    }
  }
`;
