import { gql } from "@apollo/client";

export interface ICurrentAdmin {
  id: string;
  email: string;
}

export interface ICurrentAdminData {
  currentAdmin: ICurrentAdmin;
}

export const CURRENT_ADMIN = gql`
  query currentAdmin {
    currentAdmin {
      id
      email
    }
  }
`;
