import { FC } from "react";
import {
  Avatar,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  Typography,
} from "@material-ui/core";
import {
  Category as CategoryIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  PinDrop as PinDropIcon,
  Title as TitleIcon,
} from "@material-ui/icons";
import { useStyles } from "./MapMarkerListItem.styles";
import { IMapMarker } from "../../Apollo/Queries";

interface IProps {
  item: IMapMarker;
  handleOpenEditDialog: (event: any, edit: boolean, item: IMapMarker) => void;
  handleOpenDeleteDialog: (item: IMapMarker) => void;
}

export const MapMarkerListItem: FC<IProps> = (props) => {
  const { handleOpenEditDialog, handleOpenDeleteDialog, item } = props;

  const styles = useStyles();

  const handleDelete = () => {
    handleOpenDeleteDialog(item);
  };

  const handleEdit = () => {
    handleOpenEditDialog(undefined, true, item);
  };
  return (
    <ListItem divider>
      <ListItemAvatar>
        <Avatar>
          <PinDropIcon />
        </Avatar>
      </ListItemAvatar>
      <div className={styles.listTextDiv}>
        <Typography>
          <TitleIcon className={styles.icon} />
          {item.title}
        </Typography>
        <Typography>
          <CategoryIcon className={styles.icon} />
          {item.mapMarkerCategory.name}
        </Typography>
      </div>
      <ListItemSecondaryAction>
        <IconButton color="secondary" onClick={handleEdit}>
          <EditIcon color="secondary" />
        </IconButton>
        <IconButton color="secondary" onClick={handleDelete}>
          <DeleteIcon color="secondary" />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};
