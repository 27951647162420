import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    margin: "16px",
  },
  mediaContainer: {
    position: "relative",
    flexBasis: "30%",
    height: "256px",
    overflow: "hidden",
    marginBottom: "16px",
    objectFit: "cover",
    // cursor: 'pointer',
    "&:hover $image": {
      transform: "scale(1.1)",
    },
    "&:hover $imageTitle": {
      opacity: 1,
    },
    "&:hover $deleteIcon": {
      opacity: 1,
    },
    "&:hover $expandIcon": {
      opacity: 1,
    },
  },
  button: {
    height: "100%",
    width: '100%'
  },
  image: {
    transform: "scale(1)",
    transition: "all 0.3s ease-in-out",
    objectFit: "cover",
    width: "100%",
    height: "100%",
  },
  imageTitle: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    top: "90%",
    padding: "0 8px",
    backgroundColor: "#33333399",
    color: "white",
    opacity: 0,
    transition: "all 0.3s ease-in-out",
  },
  deleteIcon: {
    position: "absolute",
    top: "4px",
    right: "4px",
    zIndex: 1,
    opacity: 0.6,
    transition: "all 0.3s ease-in-out",
  },
  expandIcon: {
    position: "absolute",
    top: "4px",
    right: "46px",
    zIndex: 1,
    opacity: 0.6,
    transition: "all 0.3s ease-in-out",
  },

  uploadAreaIcon: {
    // color: theme.palette.divider,
    fontSize: "86px",
    zIndex: 2,
    opacity: 0.5,
  },
  hidden: {
    visibility: "hidden",
  },
  uploadButton: {
    width: "100%",
    height: "150px",
    padding: 0,
    overflow: "hidden",
    position: "relative",
    transition: "all 0.3s ease-in-out",
    border: "1px solid",
    borderColor: theme.palette.divider,
    borderRadius: "8px",
    marginBottom: "16px",
  },
  uploadButtonActive: {
    width: "100%",
    height: "150px",
    padding: 0,
    overflow: "hidden",
    position: "relative",
    border: "1px dashed",
    transition: "all 0.3s ease-in-out",
    borderColor: theme.palette.secondary.main,
    borderRadius: "8px",
    marginBottom: "16px",
  },
  preview: {
    objectFit: "cover",
    width: "100%",
    height: "100%",
    borderRadius: "8px",
  },
  previewTitle: {
    color: theme.palette.common.white,
  },
}));
