import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  rte: {
    flexBasis: "100%",
    // backgroundColor: "red",
    marginTop: "16px",
  },
  slate: {
    // backgroundColor: "red",
    padding: "16px 8px",
    border: `1px solid ${theme.palette.grey[300]}`,
  },
  onError: {
    borderColor: theme.palette.error.main,
  },
}));
