import { FC, Fragment, useEffect, useRef } from "react";
import { useMutation } from "@apollo/client";
import {
  ALL_ATTRACTIONS,
  IAttraction,
  IAttractionsData,
} from "../../Apollo/Queries";
import { useStyles } from "./AddAttractionDialog.styles";
import { initialInputData } from "./AddAttractionDialog.inputs";
import { deserialize, serialize, validateForm, withMedia } from "../../utils";
import { useSnackbar } from "notistack";
import {
  IUpdateAttractionInputVars,
  CREATE_ATTRACTION,
  ICreateAttraction,
  ICreateAttractionData,
  ICreateAttractionInputVars,
  UPDATE_ATTRACTION,
} from "../../Apollo/Mutations";
import { FileUpload, RichTextEditor } from "../";
import { useForm } from "../../utils/hooks/useForm";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  LinearProgress,
  TextField,
} from "@material-ui/core";
import { Title as TitleIcon } from "@material-ui/icons";
import { createEditor, Descendant } from "slate";
import { withLinks } from "../../utils";
import { withHistory } from "slate-history";
import { withReact } from "slate-react";

interface IProps {
  onClose: () => void;
  edit: boolean | undefined;
  item: IAttraction | undefined;
}

export const AddAttractionDialog: FC<IProps> = (props) => {
  const { onClose, edit, item } = props;

  const { enqueueSnackbar } = useSnackbar();
  const styles = useStyles();

  const { inputFields, setInputFields, inputProps, setInputField } = useForm<
    keyof typeof initialInputData
  >(initialInputData);

  const editorRef = useRef(null);
  if (!editorRef.current)
    //@ts-ignore
    editorRef.current = withMedia(
      withLinks(withHistory(withReact(createEditor())))
    );
  const editor = editorRef.current;

  const handleSetRteValue = (value: Descendant[]) => {
    setInputField("contentRte", value);
  };

  const [
    createAttractionMutation,
    { loading: loadingCreateMutation },
  ] = useMutation<ICreateAttractionData, ICreateAttractionInputVars>(
    CREATE_ATTRACTION,
    {
      variables: {
        data: {
          title: inputFields.title.value as string,
          content: inputFields.content.value as string,
          media: { connect: { id: +inputFields.media.value } },
        },
      },
      onCompleted: (data) => {
        // console.log("Create data: ", data);
        enqueueSnackbar("Atrakcija je uspješno kreirana!", {
          variant: "success",
        });
        onClose();
      },
      onError: (err) => {
        console.error({ err });
        enqueueSnackbar(err.message, {
          variant: "error",
        });
      },
      update: (cache, { data }) => {
        const existingAttractions: IAttractionsData | null = cache.readQuery({
          query: ALL_ATTRACTIONS,
          variables: {
            filter: {
              where: {
                expired: null,
              },
              orderBy: {
                created: "desc",
              },
            },
          },
        });

        if (data?.createAttraction) {
          const newAttraction: ICreateAttraction = {
            ...data.createAttraction,
          };
          cache.writeQuery({
            query: ALL_ATTRACTIONS,
            variables: {
              filter: {
                where: {
                  expired: null,
                },
                orderBy: {
                  created: "desc",
                },
              },
            },
            data: {
              attractions: existingAttractions?.attractions
                ? [newAttraction, ...existingAttractions.attractions]
                : [newAttraction],
            },
          });
        }
      },
    }
  );

  const [
    updateAttractionMutation,
    { loading: loadingUpdateMutation },
  ] = useMutation<IAttraction, IUpdateAttractionInputVars>(UPDATE_ATTRACTION, {
    onCompleted: (data) => {
      // console.log("Create data: ", data);
      enqueueSnackbar("Turistička atrakcija je uspješno ažurirana", {
        variant: "success",
      });
      onClose();
    },
    onError: (err) => {
      // console.error({ err });
      enqueueSnackbar(err.message, {
        variant: "error",
      });
    },
  });

  useEffect(() => {
    if (edit && item) {
      setInputFields((prevState) => {
        return {
          ...prevState,
          title: {
            ...prevState.title,
            value: item.title,
          },
          media: {
            ...prevState.media,
            value: item.media?.id || "",
          },
          content: { ...prevState.content, value: item.content },
        };
      });
      const document = new DOMParser().parseFromString(
        item.content || "<p></p>",
        "text/html"
      );
      handleSetRteValue(
        //@ts-ignore
        item.content
          ? deserialize(document.body)
          : [
              {
                type: "paragraph",
                children: [{ text: "" }],
              },
            ]
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edit, item]);

  const handleOnBlurRte = () => {
    //@ts-ignore
    setInputField("content", serialize(editor));
  };

  const handleCreateOrEditAttraction = () => {
    const fields = ["contentRte", "title", "media"];
    const valid = validateForm(fields, inputFields);
    if (valid.isValid) {
      if (edit && item?.id) {
        updateAttractionMutation({
          variables: {
            id: item.id,
            data: {
              title: inputFields.title.value as string,
              content: inputFields.content.value as string,
              media: { connect: { id: +inputFields.media.value } },
            },
          },
        });
      } else {
        createAttractionMutation();
      }
    } else {
      fields.map((item: string) => {
        setInputFields((prevState) => {
          return {
            ...prevState,
            [item]: {
              ...(prevState as any)[item],
              error: valid.outputData[item].error,
              helperText: valid.outputData[item].helperText,
            },
          };
        });
        return null;
      });
    }
  };

  const handleChangeMedia = (id: string) => {
    // console.log(setInputField);
    setInputField("media", id);
  };

  return (
    <Fragment>
      <DialogTitle>
        {edit
          ? `Uređivanje atrakcije: ${item?.title}`
          : "Dodavanje nove atrakcije"}
      </DialogTitle>
      <DialogContent className={styles.container}>
        <TextField
          {...inputProps("title")}
          color="secondary"
          variant="outlined"
          className={styles.input}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <TitleIcon />
              </InputAdornment>
            ),
          }}
        />
        <FileUpload
          className={styles.fileUpload}
          onChangeMedia={handleChangeMedia}
          currentImageId={inputFields.media.value as string}
          errored={inputFields.media.error}
          type="image"
        />
        <RichTextEditor
          editor={editor}
          value={inputFields.contentRte.value}
          placeholder="Unesite tekst..."
          handleSetValue={handleSetRteValue}
          handleOnBlur={handleOnBlurRte}
          errored={inputFields.contentRte.error}
        />
      </DialogContent>

      <DialogActions>
        <Button variant="text" color="primary" onClick={() => onClose()}>
          Odustani
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleCreateOrEditAttraction}
        >
          {edit ? "Izmjeni" : "Dodaj"}
        </Button>
      </DialogActions>
      <Dialog
        open={loadingCreateMutation || loadingUpdateMutation}
        fullScreen
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
      >
        <LinearProgress color="secondary" />
      </Dialog>
    </Fragment>
  );
};
