import { FC, ReactChild } from "react";
import { Divider } from "@material-ui/core";
import {
  Code as CodeIcon,
  Filter1 as Filter1Icon,
  Filter2 as Filter2Icon,
  Filter3 as Filter3Icon,
  FormatBold as FormatBoldIcon,
  FormatItalic as FormatItalicIcon,
  FormatListBulleted as FormatListBulletedIcon,
  FormatListNumbered as FormatListNumberedIcon,
  FormatQuote as FormatQuoteIcon,
  FormatUnderlined as FormatUnderlinedIcon,
} from "@material-ui/icons";
import { useStyles } from "./ToolbarRte.styles";
import {
  LinkButton,
  MarkButton,
  BlockButton,
  RemoveLinkButton,
  ImageButton,
  VideoButton,
} from "../../";
import { TFormat } from "../../../utils";

interface IToolbar {
  children?: ReactChild | ReactChild[];
  include?: TFormat[];
}

export const ToolbarRte: FC<IToolbar> = (props) => {
  // const { children, include } = props;

  const styles = useStyles();

  // const markers = [
  //   "paragraph",
  //   "bold",
  //   "italic",
  //   "underline",
  //   "heading-one",
  //   "heading-two",
  // ];

  // const blocks = [
  //   "code",
  //   "block-quote",
  //   "numbered-list",
  //   "bulleted-list",
  //   "list-item",
  // ];

  // const other = ["image", "video", "link"];

  // const renderObjects = (type: "markers" | "blocks" | "other") => {
  //   let buttons = include?.length
  //     ? include
  //     : [
  //         "paragraph",
  //         "image",
  //         "video",
  //         "bold",
  //         "link",
  //         "italic",
  //         "underline",
  //         "code",
  //         "heading-one",
  //         "heading-two",
  //         "block-quote",
  //         "numbered-list",
  //         "bulleted-list",
  //         "list-item",
  //       ];
  //   return buttons.reduce((all, item, index) => {
  //     if (type === "markers" && markers.includes(item)) {
  //       return all.concat(
  //         //@ts-ignore
  //         <MarkButton format={item} />
  //       );
  //     }
  //     if (type === "blocks" && markers.includes(item)) {
  //       return all.concat(
  //         //@ts-ignore
  //         <BlockButton format={item} />
  //       );
  //     }
  //   }, [] as Element[]);
  // };

  // return <div>{children}</div>;
  return (
    <div className={styles.toolbar}>
      <MarkButton format="bold" icon={<FormatBoldIcon />} />
      <MarkButton format="italic" icon={<FormatItalicIcon />} />
      <MarkButton format="underline" icon={<FormatUnderlinedIcon />} />
      <MarkButton format="code" icon={<CodeIcon />} />
      <BlockButton format="heading-one" icon={<Filter1Icon />} />
      <BlockButton format="heading-two" icon={<Filter2Icon />} />
      <BlockButton format="heading-three" icon={<Filter3Icon />} />
      <BlockButton format="block-quote" icon={<FormatQuoteIcon />} />
      <BlockButton format="numbered-list" icon={<FormatListNumberedIcon />} />
      <BlockButton format="bulleted-list" icon={<FormatListBulletedIcon />} />
      <Divider flexItem />
      <LinkButton />
      <RemoveLinkButton />
      <ImageButton />
      <VideoButton />
    </div>
  );
};
