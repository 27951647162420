import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { Image as ImageIcon, Link as LinkIcon } from "@material-ui/icons";
import { FC, Fragment, useEffect, useRef, useState } from "react";
import { useSlateStatic } from "slate-react";
import { validateForm, insertMedia, isMediaActive } from "../../../utils";
import { useForm } from "../../../utils/hooks/useForm";
import { FileUpload } from "../..";
import { initialInputData } from "./ImageButton.inputs";
import { useStyles } from "./ImageButton.styles";

export const ImageButton: FC = () => {
  const editor = useSlateStatic();
  const styles = useStyles();

  const { inputFields, setInputFields, inputProps, resetFields } =
    useForm(initialInputData);
  const [openDialog, setOpenDialog] = useState(false);
  const [mediaInsert, setMediaInsert] = useState(true);
  const [mediaLinkPath, setMediaLinkPath] = useState("");
  const editorSelection = useRef(editor.selection);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    resetFields();
  };

  const handleChangeMedia = (id: string, path: string) => {
    setInputFields((prevState: any) => {
      return {
        ...prevState,
        media: { ...prevState.media, value: id },
      };
    });
    setMediaLinkPath(path);
  };

  const handleSwitchLinkOrMedia = () => {
    setMediaInsert(!mediaInsert);
    resetFields();
  };

  useEffect(() => {
    if (openDialog) {
      editorSelection.current = editor.selection;
      // console.log(editorSelection.current);
    }
    // return () => Transforms.deselect(editor);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openDialog]);

  const handleInsertMedia = () => {
    const fields = ["media"];
    const valid = validateForm(fields, inputFields);
    if (valid.isValid) {
      // console.log(inputFields.media.value);
      editor.selection = editorSelection.current;
      insertMedia(
        editor,
        mediaLinkPath
          ? `${process.env.REACT_APP_BUCKET_ENDPOINT}${mediaLinkPath}`
          : (inputFields.media.value as string),
        "image"
      );
      resetFields();
      handleCloseDialog();
    } else {
      fields.map((item: string) => {
        setInputFields((prevState) => {
          return {
            ...prevState,
            [item]: {
              ...(prevState as any)[item],
              error: true,
            },
          };
        });
        return null;
      });
    }
  };

  return (
    <Fragment>
      <Button
        color={isMediaActive(editor) ? "secondary" : "default"}
        onMouseDown={handleOpenDialog}
      >
        <ImageIcon />
      </Button>
      <Dialog
        maxWidth="md"
        fullWidth
        open={openDialog}
        onClose={handleCloseDialog}
      >
        <DialogTitle>Odaberite medij</DialogTitle>
        <DialogContent className={styles.mediaPickerContainer}>
          <ButtonGroup>
            <Tooltip title="Fotografija">
              <Button
                color={mediaInsert ? "primary" : "default"}
                variant={mediaInsert ? "contained" : "outlined"}
                onClick={handleSwitchLinkOrMedia}
              >
                <ImageIcon />
              </Button>
            </Tooltip>
            <Tooltip title="Link">
              <Button
                color={mediaInsert ? "default" : "primary"}
                variant={mediaInsert ? "outlined" : "contained"}
                onClick={handleSwitchLinkOrMedia}
              >
                <LinkIcon />
              </Button>
            </Tooltip>
          </ButtonGroup>
          {!mediaInsert ? (
            <TextField
              {...inputProps("media")}
              color="secondary"
              variant="outlined"
              fullWidth
              className={styles.fileUpload}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LinkIcon />
                  </InputAdornment>
                ),
              }}
            />
          ) : (
            <FileUpload
              currentImageId={inputFields.media.value as string}
              onChangeMedia={handleChangeMedia}
              type={"image"}
              className={styles.fileUpload}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="text" color="primary" onClick={handleCloseDialog}>
            Odustani
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleInsertMedia}
          >
            Potvrdi
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};
