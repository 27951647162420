import { gql } from "@apollo/client";

export interface ICreatePersonData {
  id: string;
  email: string;
}

export interface ICreatePerson {
  createPerson: ICreatePersonData;
}

export interface ICreatePersonInputVars {
  data: {
    email: string;
    password: string;
  };
}

export const CREATE_PERSON = gql`
  mutation createPerson($data: PersonCreateInput) {
    createPerson(data: $data) {
      id
      email
      created
      updated
      expired
    }
  }
`;
