import { gql } from "@apollo/client";

export interface ILoginVars {
  data: object;
}

export const LOGIN = gql`
  mutation login($data: LoginInput) {
    login(data: $data) {
      token
    }
  }
`;
