import { FC } from "react";
import { Typography } from "@material-ui/core";
import { useStyles } from "./Error.styles";

interface IProps {
  small?: boolean;
}
export const Error: FC<IProps> = (props) => {
  const { small } = props;
  const styles = useStyles();

  return (
    <div className={small ? styles.rootSmall : styles.root}>
      <Typography variant={small ? "h5" : "h3"}>Došlo je do greške!</Typography>
    </div>
  );
};
