import { Attributes, FC, ReactChild } from "react";

interface IProps {
  children: ReactChild | ReactChild[];
  attributes: Attributes;
  leaf: any;
}

export const Leaf: FC<IProps> = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }
  if (leaf.code) {
    children = <code>{children}</code>;
  }
  if (leaf.italic) {
    children = <em>{children}</em>;
  }
  if (leaf.underline) {
    children = <u>{children}</u>;
  }
  return <span {...attributes}>{children}</span>;
};
