import { FC, Fragment } from "react";
import { useMutation } from "@apollo/client";
import { initialInputData } from "./ResetPasswordDialog.inputs";
import { validateForm } from "../../utils";
import { useSnackbar } from "notistack";
import { IUpdatePersonInputVars, UPDATE_PERSON } from "../../Apollo/Mutations";
import { useForm } from "../../utils/hooks/useForm";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  LinearProgress,
  TextField,
} from "@material-ui/core";
import { Lock as LockIcon, LockOpen as LockOpenIcon } from "@material-ui/icons";

interface IProps {
  onClose: () => void;
  adminId: string;
  adminEmail: string;
}

export const ResetPasswordDialog: FC<IProps> = (props) => {
  const { onClose, adminId, adminEmail } = props;

  const { enqueueSnackbar } = useSnackbar();

  const { inputFields, setInputFields, inputProps } = useForm<
    keyof typeof initialInputData
  >(initialInputData);

  const [
    updatePersonMutation,
    { loading: loadingUpdateMutation },
  ] = useMutation<any, IUpdatePersonInputVars>(UPDATE_PERSON, {
    onCompleted: (data) => {
      enqueueSnackbar("Lozinka je uspješno ažurirana!", {
        variant: "success",
      });
      onClose();
    },
    onError: (err) => {
      // console.error({ err });
      enqueueSnackbar(err.message, {
        variant: "error",
      });
    },
  });

  const handleUpdatePassword = () => {
    const fields = ["password", "passwordReset", "passwordResetRepeat"];
    const valid = validateForm(fields, inputFields);
    if (valid.isValid) {
      if (
        inputFields.passwordReset.value ===
        inputFields.passwordResetRepeat.value
      ) {
        updatePersonMutation({
          variables: {
            id: adminId,
            data: {
              password: inputFields.password.value as string,
              passwordReset: inputFields.passwordReset.value as string,
            },
          },
        });
      } else {
        // console.log("Else");
        setInputFields((prevState) => {
          return {
            ...prevState,
            passwordResetRepeat: {
              ...(prevState as any).passwordResetRepeat,
              error: true,
              errorText: inputFields.passwordResetRepeat.errorText,
            },
          };
        });
      }
    } else {
      fields.map((item: string) => {
        setInputFields((prevState) => {
          return {
            ...prevState,
            [item]: {
              ...(prevState as any)[item],
              error: valid.outputData[item].error,
              errorText: valid.outputData[item].errorText,
            },
          };
        });
        return null;
      });
    }
  };

  // console.log({ ...inputProps("passwordResetRepeat") });

  return (
    <Fragment>
      <DialogTitle>Promijena lozinke</DialogTitle>
      <DialogContent>
        <form>
          <input
            autoComplete="username"
            type="hidden"
            defaultValue={adminEmail}
          />
          <TextField
            {...inputProps("password")}
            fullWidth
            color="secondary"
            variant="outlined"
            margin="normal"
            autoComplete="current-password"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            {...inputProps("passwordReset")}
            fullWidth
            color="secondary"
            autoComplete="new-password"
            margin="normal"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockOpenIcon />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            {...inputProps("passwordResetRepeat")}
            fullWidth
            margin="normal"
            autoComplete="new-password"
            color="secondary"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockOpenIcon />
                </InputAdornment>
              ),
            }}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="primary" onClick={() => onClose()}>
          Odustani
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleUpdatePassword}
        >
          Potvrdi
        </Button>
      </DialogActions>
      <Dialog
        open={loadingUpdateMutation}
        fullScreen
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
      >
        <LinearProgress color="secondary" />
      </Dialog>
    </Fragment>
  );
};
