import { createTypedInputs } from "../../../utils";

export const initialInputData = createTypedInputs({
  link: {
    value: "",
    label: "Url",
    required: true,
    error: false,
  },
});
