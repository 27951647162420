import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  image: {
    width: "100%",
    height: "100%",
    borderRadius: "8px",
    objectFit: "cover",
  },
  mediaHolder: {
    position: "relative",
    width: "128px",
    height: "64px",
    marginRight: "8px",
  },
  listTextDiv: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    "& > p": {
      flex: 1,
    },
  },
  icon: {
    verticalAlign: "middle",
    marginRight: "8px",
  },
  mediaIcon: {
    zIndex: 1,
    opacity: 0.5,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -60%)",
  },
}));
