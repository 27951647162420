import { ReactEditor } from "slate-react";
import {
  Editor,
  Transforms,
  BaseEditor,
  Range,
  Element as SlateElement,
  Descendant,
} from "slate";
import isUrl from "is-url";
import imageExtensions from "image-extensions";
import videoExtensions from "video-extensions";

import escapeHtml from "escape-html";
import { Text } from "slate";
import { jsx } from "slate-hyperscript";

type CustomElement = {
  type: TFormat;
  children: CustomText[];
  url?: string;
};
type CustomText = {
  text: string;
  bold?: boolean;
  italic?: boolean;
  underline?: boolean;
  code?: boolean;
};

declare module "slate" {
  interface CustomTypes {
    Editor: BaseEditor & ReactEditor;
    Element: CustomElement;
    Text: CustomText;
  }
}

export type TFormat =
  | "paragraph"
  | "image"
  | "video"
  | "youtube"
  | "bold"
  | "link"
  | "italic"
  | "underline"
  | "code"
  | "heading-one"
  | "heading-two"
  | "heading-three"
  | "heading-four"
  | "heading-five"
  | "heading-six"
  | "block-quote"
  | "numbered-list"
  | "bulleted-list"
  | "list-item";

export const LIST_TYPES = ["numbered-list", "bulleted-list"];

const ELEMENT_TAGS = {
  A: (el: Element) => ({ type: "link", url: el.getAttribute("href") }),
  BLOCKQUOTE: () => ({ type: "quote" }),
  H1: () => ({ type: "heading-one" }),
  H2: () => ({ type: "heading-two" }),
  H3: () => ({ type: "heading-three" }),
  H4: () => ({ type: "heading-four" }),
  H5: () => ({ type: "heading-five" }),
  H6: () => ({ type: "heading-six" }),
  IMG: (el: Element) => ({ type: "image", url: el.getAttribute("src") }),
  VID: (el: Element) => ({ type: "video", url: el.getAttribute("src") }),
  IFRAME: (el: Element) => ({ type: "youtube", url: el.getAttribute("src") }),
  LI: () => ({ type: "list-item" }),
  OL: () => ({ type: "numbered-list" }),
  P: () => ({ type: "paragraph" }),
  PRE: () => ({ type: "code" }),
  UL: () => ({ type: "bulleted-list" }),
};

// COMPAT: `B` is omitted here because Google Docs uses `<b>` in weird ways.
const TEXT_TAGS = {
  CODE: () => ({ code: true }),
  DEL: () => ({ strikethrough: true }),
  EM: () => ({ italic: true }),
  I: () => ({ italic: true }),
  S: () => ({ strikethrough: true }),
  STRONG: () => ({ bold: true }),
  U: () => ({ underline: true }),
};

export const isSlateEmpty = (value: Descendant[]) => {
  if (value.length === 1) {
    if (
      //@ts-ignore
      value[0].children?.length === 1 &&
      //@ts-ignore
      value[0].children[0]?.text === ""
    ) {
      return true;
    }
  }
  return false;
};

export const withLinks = (editor: Editor) => {
  const { insertData, insertText, isInline } = editor;

  editor.isInline = (element) => {
    return element.type === "link" ? true : isInline(element);
  };

  editor.insertText = (text) => {
    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertText(text);
    }
  };

  editor.insertData = (data) => {
    const text = data.getData("text/plain");

    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertData(data);
    }
  };

  return editor;
};

export const insertLink = (editor: Editor, url: string) => {
  // console.log("Insert link");
  if (editor.selection) {
    // console.log("Go wrap");
    wrapLink(editor, url);
  }
};

export const isLinkActive = (editor: Editor) => {
  const [link] = Editor.nodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "link",
  });
  return !!link;
};

export const isMediaActive = (editor: Editor) => {
  const [media] = Editor.nodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      (n.type === "video" || n.type === "image"),
  });
  return !!media;
};

const wrapLink = (editor: Editor, url: string) => {
  // console.log("Wrap");
  if (isLinkActive(editor)) {
    unwrapLink(editor);
  }

  const { selection } = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);
  const link: CustomElement = {
    type: "link",
    url,
    children: isCollapsed ? [{ text: url }] : [],
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, link);
  } else {
    Transforms.wrapNodes(editor, link, { split: true });
    Transforms.collapse(editor, { edge: "end" });
  }
};

export const unwrapLink = (editor: Editor) => {
  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "link",
  });
};

export const withMedia = (editor: Editor) => {
  const { insertData, isVoid } = editor;

  editor.isVoid = (element) => {
    return element.type === "image" ||
      element.type === "video" ||
      element.type === "youtube"
      ? true
      : isVoid(element);
  };

  editor.insertData = (data) => {
    const text = data.getData("text/plain");

    // const { files } = data;

    // if (files && files.length > 0) {
    //   for (const file of files) {
    //     const reader = new FileReader();
    //     const [mime] = file.type.split("/");

    //     if (mime === "image") {
    //       reader.addEventListener("load", () => {
    //         const url = reader.result;
    //         insertMedia(editor, url as string, "image");
    //       });
    //       reader.readAsDataURL(file);
    //     }
    //   }
    // } else if (isImageUrl(text)) {
    if (isMediaUrl(text)) {
      insertMedia(editor, text, isMediaUrl(text) as "image" | "video");
    } else {
      insertData(data);
    }
  };

  return editor;
};

export const insertMedia = (
  editor: Editor,
  url: string,
  type: "image" | "video" | "youtube"
) => {
  const text = { text: "" };
  const media: CustomElement[] = [
    { type, url, children: [text] },
    { type: "paragraph", children: [{ text: "" }] },
  ];
  Transforms.insertNodes(editor, media);
};

const isMediaUrl = (url: string) => {
  if (!url) return false;
  if (!isUrl(url)) return false;
  const ext = new URL(url).pathname.split(".").pop();

  if (imageExtensions.includes(ext!)) return "image";
  else if (videoExtensions.includes(ext!)) return "video";
  else return false;
};

export const serialize = (node: SlateElement | CustomText) => {
  if (Text.isText(node)) {
    let string = escapeHtml(node.text);
    if (node.bold) {
      string = `<strong>${string}</strong>`;
    }
    if (node.italic) {
      string = `<em>${string}</em>`;
    }
    if (node.underline) {
      string = `<u>${string}</u>`;
    }
    if (node.code) {
      string = `<code>${string}</code>`;
    }
    return string;
  }

  const children: string = node.children.map((n) => serialize(n)).join("");

  switch (node.type) {
    case "block-quote":
      return `<blockquote><p>${children}</p></blockquote>`;
    // case "code":
    //   return `<code><p>${children}</p></code>`;
    case "paragraph":
      return `<p>${children}</p>`;
    case "heading-one":
      return `<h1>${children}</h1>`;
    case "heading-two":
      return `<h2>${children}</h2>`;
    case "heading-three":
      return `<h3>${children}</h3>`;
    case "heading-four":
      return `<h4>${children}</h4>`;
    case "heading-five":
      return `<h5>${children}</h5>`;
    case "heading-six":
      return `<h6>${children}</h6>`;
    case "link":
      return `<a href="${escapeHtml(node.url)}">${children}</a>`;
    case "image":
      return `<img alt="" src="${escapeHtml(node.url)}"/>`;
    case "video":
      return `<video alt="" src="${escapeHtml(node.url)}"/>`;
    case "youtube":
      return `<iframe alt="" src="${escapeHtml(
        node.url
      )}?rel=0&controls=0&modestbranding=1" frameBorder="0"/>`;
    case "numbered-list":
      return `<ol>${children}</ol>`;
    case "bulleted-list":
      return `<ul>${children}</ul>`;
    case "list-item":
      return `<li>${children}</li>`;
    default:
      return children;
  }
};

export const deserialize = (el: Element | ChildNode) => {
  if (el.nodeType === 3) {
    return el.textContent;
  } else if (el.nodeType !== 1) {
    return null;
  }

  const { nodeName } = el;
  let parent: Element | ChildNode = el;

  if (
    nodeName === "PRE" &&
    el.childNodes[0] &&
    el.childNodes[0].nodeName === "CODE"
  ) {
    parent = el.childNodes[0];
  }

  const children: (string | Element | Descendant | null)[] = Array.from(
    parent.childNodes
  )
    .map(deserialize)
    .flat();

  if (!children.length) {
    children.push("");
  }

  switch (el.nodeName) {
    case "BODY":
      return jsx("fragment", {}, children);
    case "BR":
      return "\n";
    default:
      //@ts-ignore
      if (ELEMENT_TAGS[nodeName]) {
        //@ts-ignore
        const attrs = ELEMENT_TAGS[nodeName](el);
        return jsx("element", attrs, children);
      }
      //@ts-ignore
      if (TEXT_TAGS[nodeName]) {
        //@ts-ignore
        const attrs = TEXT_TAGS[nodeName](el);
        return children.map((child) => jsx("text", attrs, child));
      }
  }

  return children;
};
