import { gql } from "@apollo/client";

export interface ICreateMapMarker {
  id: number;
  title: string;
  description: string;
  link: string;
  longitude: string;
  latitude: string;
  mapMarkerCategory: {
    id: string;
    name: string;
  };
  expired: string;
  created: string;
  updated: string;
}

export interface ICreateMapMarkerData {
  createMapMarker: ICreateMapMarker;
}

export interface ICreateMapMarkerInputVars {
  data: {
    title: string;
    description: string;
    link: string;
    longitude: string;
    latitude: string;
    mapMarkerCategory: { connect: { id: number } };
  };
}

export const CREATE_MAP_MARKER = gql`
  mutation createMapMarker($data: MapMarkerCreateInput) {
    createMapMarker(data: $data) {
      id
      title
      description
      link
      longitude
      latitude
      mapMarkerCategory {
        id
        name
      }
      created
      updated
      expired
    }
  }
`;
