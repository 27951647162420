import { gql } from "@apollo/client";

export interface IDeleteAttraction {
  id: string;
}

export interface IDeleteAttractionData {
  deleteAttraction: IDeleteAttraction;
}

export interface IDeleteAttractionInputVars {
  id: number;
}

export const DELETE_ATTRACTION = gql`
  mutation deleteAttraction($id: ID) {
    deleteAttraction(id: $id) {
      id
    }
  }
`;
