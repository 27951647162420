import { createTypedInputs } from "../../utils";

export const initialInputData = createTypedInputs({
  title: {
    value: "",
    label: "Naslov",
    required: true,
    error: false,
  },
  media: {
    value: "",
    label: "Trajanje",
    required: true,
    type: "number",
    error: false,
  },
  category: {
    value: "",
    label: "Kategorija",
    required: true,
    type: "number",
    error: false,
  },
});
