import { FC, Fragment } from "react";
import { useMutation } from "@apollo/client";
import { IPersonsData, ALL_PERSONS, IPerson } from "../../Apollo/Queries";
import { initialInputData } from "./AddAdminDialog.inputs";
import { validateForm } from "../../utils";
import { useSnackbar } from "notistack";
import {
  CREATE_PERSON,
  ICreatePerson,
  ICreatePersonInputVars,
} from "../../Apollo/Mutations";
import { useForm } from "../../utils/hooks/useForm";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  LinearProgress,
  TextField,
} from "@material-ui/core";
import { Email as EmailIcon } from "@material-ui/icons";

interface IProps {
  onClose: () => void;
}

export const AddAdminDialog: FC<IProps> = (props) => {
  const { onClose } = props;

  const { enqueueSnackbar } = useSnackbar();

  const { inputFields, setInputFields, inputProps } =
    useForm<keyof typeof initialInputData>(initialInputData);

  const [createAdminMutation, { loading: loadingCreateMutation }] = useMutation<
    ICreatePerson,
    ICreatePersonInputVars
  >(CREATE_PERSON, {
    variables: {
      data: {
        email: inputFields.email.value as string,
        password: "",
      },
    },
    onCompleted: (data) => {
      // console.log("Create data: ", data);
      enqueueSnackbar("Uspješno dodan novi administrator", {
        variant: "success",
      });
      onClose();
    },
    onError: (err) => {
      // console.error({ err });
      enqueueSnackbar(err.message, {
        variant: "error",
      });
    },
    update: (cache, { data }) => {
      const existingPersons: IPersonsData | null = cache.readQuery({
        query: ALL_PERSONS,
        variables: {
          filter: {
            where: {
              expired: null,
              NOT: {
                role: {
                  access: 1,
                },
              },
            },
            orderBy: {
              created: "desc",
            },
          },
        },
      });
      if (data?.createPerson) {
        const newPerson: IPerson = {
          id: data.createPerson.id,
          email: data.createPerson.email,
        };

        cache.writeQuery({
          query: ALL_PERSONS,
          variables: {
            filter: {
              where: {
                expired: null,
                NOT: {
                  role: {
                    access: 1,
                  },
                },
              },
              orderBy: {
                created: "desc",
              },
            },
          },
          data: {
            persons: existingPersons?.persons
              ? [newPerson, ...existingPersons.persons]
              : [newPerson],
          },
        });
      }
    },
  });

  const handleCreateAdmin = () => {
    const fields = ["email"];
    const valid = validateForm(fields, inputFields);
    if (valid.isValid) {
      createAdminMutation();
    } else {
      fields.map((item: string) => {
        setInputFields((prevState) => {
          return {
            ...prevState,
            [item]: {
              ...(prevState as any)[item],
              error: true,
            },
          };
        });
        return null;
      });
    }
  };

  return (
    <Fragment>
      <DialogTitle>Dodavanje novog administratora</DialogTitle>
      <DialogContent>
        <TextField
          {...inputProps("email")}
          fullWidth
          color="secondary"
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EmailIcon />
              </InputAdornment>
            ),
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="text" color="primary" onClick={() => onClose()}>
          Odustani
        </Button>
        <Button variant="contained" color="primary" onClick={handleCreateAdmin}>
          Dodaj
        </Button>
      </DialogActions>
      <Dialog
        open={loadingCreateMutation}
        fullScreen
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
      >
        <LinearProgress color="secondary" />
      </Dialog>
    </Fragment>
  );
};
