import { FC, useCallback } from "react";
import { Descendant } from "slate";
import { Editable, Slate } from "slate-react";
import { useStyles } from "./RichTextEditor.styles";
import { SlateRteElement, Leaf, ToolbarRte } from "../";

interface IProps {
  className?: string;
  value: Descendant[];
  handleSetValue: (value: Descendant[]) => void;
  placeholder?: string;
  errored?: boolean;
  handleOnBlur: () => void;
  editor: any;
}

export const RichTextEditor: FC<IProps> = (props) => {
  const {
    className,
    handleSetValue,
    value,
    placeholder,
    errored,
    handleOnBlur,
    editor,
  } = props;
  const styles = useStyles();

  const renderElement = useCallback(
    (props) => <SlateRteElement {...props} />,
    []
  );
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);

  const onBlur = () => {
    //@ts-ignore
    handleOnBlur();
  };

  return (
    <div className={`${styles.rte} ${className || ""}`}>
      {editor && (
        <Slate editor={editor} value={value} onChange={handleSetValue}>
          <ToolbarRte />
          <Editable
            className={`${styles.slate} ${errored && styles.onError}`}
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            placeholder={placeholder ?? "Upišite tekst..."}
            autoFocus
            onBlur={onBlur}
          />
        </Slate>
      )}
    </div>
  );
};
