import { gql } from "@apollo/client";

export interface IUpdateAttractionInputVars {
  id: string;
  data: {
    title: string;
    content: string;
    media: { connect: { id: number } };
  };
}

export const UPDATE_ATTRACTION = gql`
  mutation updateAttraction($id: ID!, $data: AttractionUpdateInput) {
    updateAttraction(id: $id, data: $data) {
      id
      title
      content
      media {
        id
      }
    }
  }
`;
