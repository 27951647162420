import { Button, Dialog, IconButton } from "@material-ui/core";
import { useStyles } from "./FileUpload.styles";
import React, { FC, Fragment, useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import {
  Close as CloseIcon,
  Image as ImageIcon,
  PermMedia as PermMediaIcon,
  VideoLibrary as VideoLibraryIcon,
} from "@material-ui/icons";
import { MediaSelectorDialog } from "../MediaSelectorDialog/MediaSelectorDialog";
import { IMediaData, IMediaVars, ONE_MEDIA } from "../../Apollo/Queries";
import { Loading } from "../Loading/Loading";
import { Error } from "../Error/Error";

interface IProps {
  onChangeMedia?: (id: string, path: string) => void;
  currentImageId?: string;
  className?: string;
  type?: "image" | "video";
  errored?: boolean;
}

export const FileUpload: FC<IProps> = (props) => {
  const { onChangeMedia, currentImageId, className, type, errored } = props;
  const styles = useStyles();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [getMediaPreview, { data, loading, error }] = useLazyQuery<
    IMediaData,
    IMediaVars
  >(ONE_MEDIA, { variables: { data: { id: +currentImageId! } } });

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };
  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handlePick = (id: string, path: string) => {
    if (onChangeMedia) {
      // console.log(id);
      onChangeMedia(id, path);
      handleCloseDialog();
    }
  };

  const handleRemoveCurrentImage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    event.preventDefault();
    if (onChangeMedia) {
      onChangeMedia("", "");
    }
  };

  useEffect(() => {
    if (currentImageId) {
      getMediaPreview();
    }
  }, [currentImageId, getMediaPreview]);

  if (error) {
    // console.error({ error });
  }

  return (
    <Fragment>
      <div className={`${styles.root} ${className}`}>
        {/* <InputLabel
          className={styles.inputLabel}
          variant="filled"
          error={errored}
          shrink
          required
          disableAnimation
        >
          Slika
        </InputLabel> */}
        <Button
          className={`${styles.uploadButton} ${errored && styles.onError}`}
          onClick={handleOpenDialog}
        >
          {loading ? (
            <Loading small />
          ) : error ? (
            <Error small />
          ) : currentImageId ? (
            data?.media?.path.match(/(.*?).(mp4)$/) ? (
              <Fragment>
                <video
                  preload="metadata"
                  controls={false}
                  src={`${process.env.REACT_APP_BUCKET_ENDPOINT}${data?.media?.path}`}
                  className={styles.previewImage}
                />
                <VideoLibraryIcon
                  className={styles.uploadAreaIcon}
                  color={currentImageId ? "primary" : "inherit"}
                />
              </Fragment>
            ) : (
              <Fragment>
                <img
                  className={styles.previewImage}
                  src={`${process.env.REACT_APP_BUCKET_ENDPOINT}${data?.media?.path}`}
                  alt=""
                />
                <ImageIcon
                  className={styles.uploadAreaIcon}
                  color={currentImageId ? "primary" : "inherit"}
                />
              </Fragment>
            )
          ) : (
            <PermMediaIcon
              className={styles.uploadAreaIcon}
              color={currentImageId ? "primary" : "inherit"}
            />
          )}
        </Button>
        {currentImageId && data?.media?.path && (
          <IconButton
            onClick={handleRemoveCurrentImage}
            className={styles.removeButton}
            size="medium"
            color="secondary"
          >
            <CloseIcon color="error" fontSize="large" />
          </IconButton>
        )}
      </div>
      <Dialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        maxWidth="lg"
        fullWidth
      >
        <MediaSelectorDialog
          onHandlePick={handlePick}
          onClose={handleCloseDialog}
          type={type}
        />
      </Dialog>
    </Fragment>
  );
};
